<template>
  <div>
    <div class="item">
      <div
        v-bind:class="
          noslide
            ? ' product-layout col-lg-3 col-md-4 col-sm-6 col-xs-12'
            : 'item-inner product-layout transition product-grid'
        "
      >
        <div class="product-item-container product-grid4">
          <span
            data-v-375eb62e
            class="label-product label-sale dicountbg-2"
            v-if="data.discountprecentage != ''"
          ></span>
          <span
            data-v-375eb62e
            class="label-product label-sale dicountbg-1"
            v-if="data.discountprecentage != ''"
          ></span>
          <span
            class="label-product label-sale"
            style="
              background-color: transparent;
              line-height: 1.2;
              font-size: initial;
              font-style: italic;
            "
            v-if="data.discountprecentage != ''"
          >
            <b>{{ data.discountprecentage }}%</b>
            <br />OFF
          </span>

          <div class="left-block left-b">
            <div class="box-label">
              <!-- <span
                class="label-product label-sale"
                v-if="data.discountprecentage != ''"
              >-->
            </div>
            <div class="product-image-container second_img">
              <a
                @click="viewProduct(data.item_id)"
                target="_self"
                :title="data.title"
                v-if="data.imgpath.length > 1"
              >
                <img
                  v-for="(imagepath, index) in data.imgpath.slice(0, 2)"
                  :key="index"
                  :src="imagepath"
                  :class="'img-responsive img-' + parseInt(index + 1)"
                  style="height: 180px; width: 180px; object-fit: cover"
                />
              </a>
              <a
                @click="viewProduct(data.item_id)"
                target="_self"
                :title="data.title"
                v-if="data.imgpath.length === 1"
              >
                <img
                  v-for="index in 2"
                  :key="index"
                  :src="data.imgpath[0]"
                  :class="'img-responsive img-' + parseInt(index + 1)"
                  style="height: 180px; width: 180px; object-fit: cover"
                />
              </a>
            </div>
            <!--quickview-->
            <!-- <div class="so-quickview">                   
                    <a @click="viewProduct(data.item_id)" class="btn-button quickview quickview_handler visible-lg"><i class="fa fa-eye"></i><span>Quick view</span></a>
            </div>-->
            <!--end quickview-->
          </div>
          <div class="right-block" style="padding-top: 0px">
            <div
              class="button-group so-quickview cartinfo--left"
              style="display: none"
            >
              <!-- <button @click="addToCart(data)" type="button" class="addToCart" title="Add to cart">
                        <span>Add to cart </span>   
              </button>-->
              <button
                @click="quickViewProductModal(data.item_id)"
                type="button"
                class="addToCart"
                title="Add to cart"
              >
                <span>Add to cart</span>
              </button>

              <button
                v-if="data.wishlit === false"
                type="button"
                class="wishlist btn-button"
                title="Add to Wish List"
                @click="addToWishList(data, data.item_id)"
              >
                <i class="fa fa-heart-o"></i>
                <span>Add to Wish List</span>
              </button>

              <button
                v-if="data.wishlit === true"
                type="button"
                class="wishlist btn-button"
                style="color: #fff; background-color: #005baa"
                title="Remove to Wish List"
                @click="removeFromWishList(data, data.item_id)"
              >
                <i class="fa fa-heart-o"></i>
              </button>

              <!-- <button type="button" class="compare btn-button" title="Compare this Product "><i class="fa fa-retweet"></i><span>Compare this Product</span>
              </button>-->
            </div>
            <!-- REMOVE ANIMATION <div class="caption hide-cont"> -->
            <div class="caption">
              <div class="ratings" style="display: none">
                <div class="rating-box">
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 1 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 2 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 3 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 4 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                  <span class="fa fa-stack">
                    <i
                      :class="[
                        data.rating >= 5 ? ' fa fa-star' : 'fa fa-star-o',
                      ]"
                      class="fa-stack-2x"
                    ></i>
                  </span>
                </div>
                <!-- <span v-if="data.rating != '0'" class="rating-num">( {{data.rating}} )</span> -->
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 0px">
                <button
                  v-if="data.wishlit == false"
                  @click="addToWishList(data, data.item_id)"
                  type="button"
                  class="addToCartNew btn-title"
                  title="Add to Wish List"
                >
                  <i
                    class="fa fa-heart fa-icon"
                    style="font-size: small; padding-right: 5px"
                  ></i>
                  <span style="font-size: xx-small; font-weight: bolder"
                    >WISH LIST</span
                  >
                </button>
                <button
                  v-if="data.wishlit == true"
                  @click="removeFromWishList(data, data.item_id)"
                  type="button"
                  class="addToCartNew btn-title"
                  title="Remove from Wish List"
                >
                  <i
                    class="fa fa-heart fa-icon"
                    style="font-size: small; color: #de1a22; padding-right: 5px"
                  ></i>
                  <span style="font-size: xx-small; font-weight: bolder"
                    >WISH LIST</span
                  >
                </button>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6" style="padding: 0px">
                <button
                  @click="quickViewProductModal(data.item_id)"
                  type="button"
                  class="addToCartNew"
                  title="Add to cart"
                >
                  <i
                    class="fa fa-shopping-cart fa-icon"
                    style="font-size: small; padding-right: 5px"
                  ></i>
                  <span style="font-size: xx-small; font-weight: bolder"
                    >ADD TO CART</span
                  >
                </button>
              </div>
              <div
                class="col-md-12"
                style="height: 40px; overflow: hidden; padding-top: 5px"
              >
                <!-- <h4 style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><a @click="viewProduct(data.item_id)" :title="data.title" target="_self">{{data.title}}</a></h4> -->
                <!-- <h4
                  style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis; font-weight:bold;"
                >
                  <a
                    @click="viewProduct(data.item_id)"
                    :title="data.title"
                    target="_self"
                  >{{ data.title }}</a>
                </h4> -->

                <h4
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: bold;
                  "
                >
                  <a
                    @click="viewProduct(data.item_id)"
                    :title="data.title"
                    target="_self"
                    >{{ data.title }}</a
                  >
                </h4>
              </div>
            </div>

            <p class="price" style="text-align: center">
              <span class="price-new">Rs.{{ data.price }}</span
              >&nbsp;&nbsp;
              <!-- <span class="price-old" v-if="data.prediscount != ''">
                Rs.{{ data.prediscount }}
              </span>-->
              <span
                class="price-old"
                v-if="data.prediscount !== 0 && data.prediscount !== data.price"
                >Rs.{{ data.prediscount }}</span
              >
            </p>
            <buyer-login-modal ref="modalLogin" />
          </div>
        </div>
      </div>
      <!-- <modal ref="modal"></modal>    -->
    </div>
  </div>
</template>
<script>
import modal from "@/components/product/quick_view_product_modal.vue";
import APIProduct from "@/mixins/buyer/APIProduct";
import BuyerLoginModal from "@/views/Buyer/BuyerLogin/BuyerLloginModal.vue";
export default {
  name: "ProductTile",
  mixins: [APIProduct],
  props: {
    data: {
      type: Object,
    },
    noslide: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      cartProductIdArray: [],
      idObj: {
        ids: [],
      },
    };
  },
  components: { modal, BuyerLoginModal },
  methods: {
    quickViewProductModal: function (pid) {
      if (localStorage.getItem("buyer_token")) {
        this.$parent.parentModalTrigger(pid);
      } else {
        this.$refs.modalLogin.showModal();
      }
    },
    viewProduct: function (item_id) {
      this.$router.push({
        name: "product",
        params: { purl: "samesite" },
        query: { pid: item_id },
      });
    },
    addToCart: function (item) {
      this.idObj = JSON.parse(localStorage.getItem("cart_product_ids"));
      if (this.idObj !== null) {
        this.idObj.ids.push(item);
        localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
        this.$store.commit("setAddToCartSwitchState", {
          action: null,
          payload: this.idObj,
        });
      } else {
        let array = {
          ids: [],
        };
        array.ids.push(item);
        localStorage.setItem("cart_product_ids", JSON.stringify(array));
        this.$store.commit("setAddToCartSwitchState", {
          action: null,
          payload: array,
        });
      }
    },
    addToWishList: async function (data, item_id) {
      if (this.$store.state.buyer_accessToken === null) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Please login to add to Wishlist!",
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      } else {
        try {
          let wishlistItem = { product_id: item_id, status: 1 };
          let response = await this.saveWishliat(wishlistItem);
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Product Added to Wishlist",
            showConfirmButton: false,
            timer: 1500,
          });
          data.wishlit = true;
        } catch (error) {
          throw error;
        }
      }
    },
    removeFromWishList: async function (data, item_id) {
      try {
        let wishlistItem = { product_id: item_id, status: 2 };
        let response = await this.saveWishliat(wishlistItem);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Product Removed from Wishlist",
          showConfirmButton: false,
          timer: 1500,
        });
        data.wishlit = false;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>
<style scoped>
.addToCartNew {
  background: #ffd338 !important;
  font-size: 10px;
  font-weight: 600;
  color: #333333;
  text-transform: capitalize;
  border-radius: 6px;
  border: none;
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  line-height: 100%;
  border: none;
}
.addToCartNew:hover {
  background: #ffe677 !important;
}

.addToCart {
  background: #e82d2b !important;
}
.wishlist {
  border: 1px solid #e82d2b !important;
}
.wishlist .fa {
  color: #e82d2b;
}
.wishlist:hover {
  background: #505050 !important;
}
.wishlist:hover > .fa {
  color: #ffffff;
}

/* Sathosa UI  */
.product-grid4 {
  border-radius: 10px !important;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.product-grid4:hover {
  box-shadow: 0 0 10px rgba(187, 57, 40, 0.6);
}
@media screen and (min-width: 1280px) {
  .btn-title {
    margin-right: 5px;
  }
}
@media screen and (min-width: 1280px) {
}
.products-list.list .product-layout .product-item-container {
  /* display: table; */
  margin-bottom: 30px;
  display: block;
  width: 75%;
  height: 200px;
  background-color: white;
}
.products-list.list .product-layout .product-item-container .left-block {
  width: 270px;
  float: left;
  border: 0px solid #ddd;
}
.products-list.list .product-layout .product-item-container .right-block {
  margin-left: 270px;
  padding: 0 20px 0px 30px;
  margin-top: 10px;
}
.products-list.list .product-layout .product-item-container .right-block h4 {
  margin-bottom: 5px;
  font-weight: 400;
  padding: 0px;
  margin-left: -14px;
}
.products-list.list .product-layout .product-item-container .right-block {
  margin-left: 270px;
  padding: 0 20px 0px 30px;
  margin-top: 10px;
  margin-top: 35px;
}

/* ASHEN CSS */
.dicountbg-1 {
  width: 0;
  height: 0;
  border-top: 100px solid red;
  border-left: 100px solid transparent;
  right: 0;
  top: -2%;
  background-color: transparent;
  border-radius: 0px;
}

.dicountbg-2 {
  width: 0;
  height: 0;
  border-top: 100px solid #ffd338;
  border-left: 115px solid transparent;
  right: 0;
  top: -2%;
  background-color: transparent;
  border-radius: 0px;
  transform: scaleY(1.3);
}
</style>
