<template>
  <div class="header-home">

    <!-- Header middle starts -->
    <div
      id="buyer-loggedin-header-middle"
      class="header-middle buyer-loggedin-header-middle"
      style="margin-bottom: 0px;"
    >
      <div class="container" id="homeHeaderSectionContainer">
        <div class="row" style=" ">
          <div class="bottom1 col-lg-2 col-md-2 col-sm-2" id="homelogosection">
            <!-- <div class="nav-logo"> -->
            <!-- scdsdcsdc -->
            <div>
              <a @click="home()">
                <img
                  :src="this.$store.state.colourLogo"
                  alt="mdb logo"
                  style="border-radius: 10px;"
                />
                <!-- <img
                  src="https://res.cloudinary.com/persystance-network/image/upload/v1593918027/slt/portal_user/1593918027750.jpg"
                  alt="mdb logo"
                  style="padding: 20px;"
                />-->
              </a>
            </div>
            <!-- <nav class="navbar navbar-dark" style="">
              <a class="navbar-brand" @click="home()">
                <img src="https://res.cloudinary.com/persystance-network/image/upload/v1589092077/slt/portal_user/1589092077321.png" alt="mdb logo" style="margin-top: -16px; width: 79%; height: auto;">
              </a>
            </nav>-->
          </div>

          <div
            id="commn-search-col"
            class="col-lg-4 col-md-4 col-sm-4 searchdiv new-search-area-block"
          >
            <div class="search-header-w">
              <div id="sosearchpro" class="sosearchpro-wrapper so-search">
                <div id="search0" class="search input-group form-group">
                  <div
                    class="select_category"
                    style="background-color:transparent; border-right: 0px solid #d7d7d7;"
                  ></div>
                  <form autocomplete="off">
                    <input
                      @keyup="validateSearch()"
                      v-on:keyup.enter="pushSearch()"
                      id="common-search-input"
                      class="autosearch-input form-control"
                      type="text"
                      size="50"
                      v-model="searchtxt"
                      autocomplete="off"
                      placeholder="Search"
                    />
                    <button
                      type="button"
                      :disabled="searchdisable"
                      class="btn"
                      name="submit_search"
                      @click="pushSearch()"
                    >
                      <!-- style="padding-left:18px;padding-right:18px;background-color:white; border-left: 1px solid black;" -->
                      <i
                        style="color: white; font-size: 16px;"
                        class="fa fa-search"
                      ></i>
                    </button>
                  </form>
                </div>
                <input type="hidden" name="route" value="product/search" />
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-4 col-md-4 col-sm-4"
          >
            <ul class="signin-link blank">
                <li v-if="this.$store.state.buyer_accessToken === null" class="log login">
                  <a v-on:mouseup="loginNew" class="link-lg">Login | Signup</a>
                </li>
                <li v-if="this.$store.state.buyer_accessToken !== null" class="log login">
                  <i class="fa fa-user" style="font-size:14px;"></i>
                  <a
                    v-if="buyer !== 'undefined undefined'"
                    @click="handleProfile"
                    class="user-name"
                  >
                    &nbsp;&nbsp;
                    <B>{{buyer}}</B>
                  </a>
                  <a
                    v-if="buyer === 'undefined undefined'"
                    id="profileLbl"
                    @click="handleProfile"
                    class="user-name"
                  >{{ProfileLbl}}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <i class="fa fa-unlock"></i>
                  <a v-on:mouseup="logout" class="user-name">&nbsp;&nbsp;Log Out</a>
                </li>
            </ul>
           

          </div>-->

          <!-- start -->
          <div
            class="cartbuttondiv cartbuttondiv-logged-in col-lg-6 col-md-6 col-sm-6 new-icon-area-block"
            style="padding-right: 0px;"
          >
            <!-- <div class="col-lg-4 col-md-4 col-sm-4"> -->
            <!-- hidden-md hidden-sm hidden-xs  -->

            <!-- Shopping cart starts -->
            <div v-if="no_of_cart_items >= 0" class="shopping_cart buyer-loggedin-shopping_cart">
              <div
                id="cart"
                class="btn-shopping-cart"
                style="margin-top: -5px;"
              >
                <a
                  data-loading-text="Loading... "
                  @click="logToCart"
                  class="btn-group top_cart dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="true"
                >
                  <div class="shopcart shopcart-home">
                    <ul class="wishlist-comp" style="margin-right: 0px;">
                      <li
                        class="wishlist"
                        style="margin-left: 0px; width: auto;"
                      >
                        <div class="cart-count-block home-cart-count-block" :style="no_of_cart_items === 0 ? 'pointer-events: none; opacity: 0.4' : ''">
                          <div class="shopcart-inner buyer-loggedin-shopcart-inner" @click="cart">
                            <span class="total-shopping-cart cart-total-full">
                              <span class="items_cart cart-item-count buyer-loggedin-cart-item-count">{{ no_of_cart_items }}</span>
                              <span class="items_cart2">item(s)</span>
                            </span>
                             <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div class="my-cart-btn-block buyer-loggedin-my-cart-btn-block">
                          <button
                            class="btn my-cart-btn"
                            style="font-size: 14px;"
                            :disabled="no_of_cart_items === 0"
                          >
                            My Cart
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </a>
                <ul
                  v-if="no_of_cart_items !== 0"
                  class="dropdown-menu pull-right shoppingcart-box cart-dropdown"
                  role="menu"
                  style="margin-top: 2px;"
                >
                  <li>
                    <table class="table table-striped table-cart">
                      <tbody>
                        <tr
                          v-for="(itemp, index) in cart_products"
                          :key="index"
                        >
                          <td class="text-center" style="width:70px">
                            <a href="#">
                              <img
                                :src="itemp.imgpath[0]"
                                style="width:70px"
                                alt="Yutculpa ullamcon"
                                title="Yutculpa ullamco"
                                class="preview"
                              />
                            </a>
                          </td>
                          <td class="text-left">
                            <a
                              class="cart_product_name"
                              @click="pushToItem(itemp.item_id)"
                              >{{ itemp.product_name }}</a
                            >
                          </td>
                          <td class="text-center">
                            x{{ itemp.primary_product.selected_qty }}
                          </td>
                          <td class="text-center">
                            {{
                              (itemp.primary_product.special_price *
                                itemp.primary_product.selected_qty).toFixed(2)
                            }}
                          </td>
                          <td class="text-right"></td>
                          <td class="text-right">
                            <a
                              @click="removeCartItems(itemp, index)"
                              class="fa fa-times fa-delete"
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <li>
                    <div>
                      <p class="text-right">
                        <button
                          @click="cart"
                          style="margin-right:10px; margin-top:10px; color: #fff; background-color: #005baa;"
                          class="img-thumbnail verified-badge view-cart"
                        >
                          <div class="tick-span">
                            <span style="border-radius:50%;">
                              <i
                                class="fa fa-shopping-cart"
                                aria-hidden="true"
                              ></i> </span
                            >&nbsp;View Cart
                          </div>
                        </button>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Shopping cart ends -->

            <!-- Login Register starts -->
            <!-- <div
              class="signin-w"
              style="padding-top: 5px;padding-left: 0px;margin-left: 2%;"
            >
              <ul class="signin-link blank">
                <li
                  v-if="this.$store.state.buyer_accessToken === null"
                  class="log login"
                >
                  <div class="signin-link-icon">
                    <i class="fa fa-user-o" aria-hidden="true"></i>
                  </div>
                  <div class="signin-link-text">
                    <div class="my-account">My Account</div>
                    <div class="login-register">
                      <a v-on:mouseup="loginNew" class="link-lg">
                          <span class="text-color-headder login-signup-text"><i class="fa fa-user-o mobile-user-icon-hp" aria-hidden="true"></i> Login / Register</span>
                      </a>
                    </div>
                  </div>
                </li> -->
               
                <!-- New Logged in link block Start -->
                <!-- <li
                  class="new-loggedin-link-block"
                  v-if="this.$store.state.buyer_accessToken !== null"
                > -->
               
                  <!-- <div class="link-block-left">
                    <ul class="list-group">
                      <li>
                        <a href=""><img :src="contactImage" alt=""></a>
                        <ul id="">
                          <li class="list-group-item" v-if="userRole == 1"><a @click="handleProfile">Profile</a></li>
                          <li class="list-group-item"><a @click="myOrders">My Orders</a></li>
                          <li class="list-group-item"><a @click="wishlist">Wishlist</a></li>
                          <li class="list-group-item"><a @click="disputeReturn">Dispute & Return</a></li>
                          <li class="list-group-item"><a @click="myReviews">My Reviews</a></li>
                        </ul>
                      </li>
                     </ul>
                  </div>
                  <div class="link-block-right">
                    <h5>My Account</h5>
                    <div class="logged-buyer-name"><a @click="handleProfile"><b>{{ buyer }}</b></a></div>
                    <div class="logoutbuyer-block"><a @click="logout"><i class="fa fa-unlock text-color-headder" style="font-size: 14px;margin-right: 6px;" ></i> Logout</a></div>
                  </div>
                </li> -->
                <!-- New Logged in link block End -->
              <!-- </ul>
            </div> -->
            <!-- Login Register ends -->


          </div>
          <!-- end -->

           <!-- Below Tab Section Commented on 29th Sep 2020 -->
          <!-- <div
            id="hometabs"
            class="col-lg-10 col-md-10 col-sm-12 col-xs-12 banner-top-nav"
          > -->
 
            <!-- <div id="hometabsbuttons">
              <router-link
                :to="{
                  name: 'Product List Home Tabs',
                  query: { type: 'offers' },
                }"
              >
                <button class="btn addToCartNew" style="margin-right: 2px;">
                  OFFERS
                </button>
              </router-link>
              <router-link
                :to="{ name: 'Product List Home Tabs', query: { type: 'new' } }"
              >
                <button class="btn addToCartNew" style="margin-right: 2px;">
                  NEW ARRIVALS
                </button>
              </router-link>
              <router-link
                :to="{
                  name: 'Product List Home Tabs',
                  query: { type: 'popular' },
                }"
              >
                <button class="btn addToCartNew" style="margin-right: 2px;">
                  MOST POPULAR
                </button>
              </router-link>
            </div> -->
          <!-- </div> -->
           <!-- Above Tab Section Commented on 29th Sep 2020 -->


        </div>
      </div>
    </div>
    <!-- Header middle ends -->


    <!-- Delivery partner & sell on cochchi area starts -->
    <div class="header-top hidden-compact home-header-bottom" style="min-height: 0px; background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%); color: white;">
      <div class="container">
        <div class="row">
          <div class="header-top-left col-lg-6 col-md-3 col-sm-3 col-xs-3">
            <div class="hidden-md hidden-sm hidden-xs welcome-msg">
              <!-- Welcome to Supiripola! Wrap new offers / gift every single day on
              Weekends -->
            </div>
            <ul class="top-link list-inline hidden-lg">
              <li class="account" id="my_account">
                <a
                  @click="home"
                  title="My Account "
                  class="btn-xs dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span class="hidden-xs" style="color: white;">&nbsp;My Account</span>&nbsp;&nbsp;
                  <span class="fa fa-caret-down" style="color: white;"></span>
                </a>
                <ul class="dropdown-menu">
                  <!-- <li
                    v-if="this.$store.state.buyer_accessToken === null"
                    class="log login"
                  >
                    <a data-toggle="modal" data-target="#BuyerLoginModal">
                      <i class="fa fa-pencil-square-o"></i> &nbsp;&nbsp;Login or
                      Register
                    </a>
                  </li> -->

                  <!-- <li
                    v-if="this.$store.state.buyer_accessToken !== null"
                    class="log login"
                  >
                    <a @click="handleProfile" v-if="userRole == 1">
                      <i class="fa fa-user"></i> &nbsp;&nbsp;Profile
                    </a>
                  </li> -->

                  <!-- <li class="log login">
                    <a @click="deliverLanding">
                      <i class="fa fa-truck"></i>
                      &nbsp;&nbsp;Delivery&nbsp;Partner
                    </a>
                  </li> -->

                  <li class="log login">
                    <a @click="merchantLandingnew">
                      <i class="fa fa-shopping-cart"></i>
                       &nbsp;&nbsp;Sell&nbsp;on&nbsp;Cochchi
                    </a>
                  </li>

                  <li class="log login">
                    <a @click="haddleETeleshop()">
                      <i class="fa fa-shopping-cart"></i>
                      &nbsp;&nbsp;E - Teleshop
                    </a>
                  </li>

                  <li class="log login">
                    <a @click="haddleMadeInSrilanka()">
                      <i class="fa fa-shopping-cart"></i>
                      &nbsp;&nbsp;Made in Sri Lanka
                    </a>
                  </li>

                  <!-- <li
                    v-if="this.$store.state.buyer_accessToken !== null"
                    class="log login"
                  >
                    <a @click="logout">
                      <i class="fa fa-unlock"></i> &nbsp;&nbsp;Log Out
                    </a>
                  </li> -->
                </ul>
              </li>
            </ul>
          </div>
          <div
            class="header-top-right header-top-right-common collapsed-block col-lg-6 col-md-9 col-sm-9 col-xs-9 buyer-common-newlink-list"
          >
            <ul class="top-link list-inline lang-curr">
              <!-- <li class="sellerLog hidden-md hidden-sm hidden-xs">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding">New&nbsp;Arrivals</a>
                            </div>                              
              </li>-->
              <!-- <li class="sellerLog hidden-md hidden-sm hidden-xs">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding">Most&nbsp;Viewed</a>
                            </div>                              
              </li>-->
              <!-- <li class="sellerLog hidden-sm hidden-xs">
                <div class="btn-group currencies-block home-nav">
                  <a @click="deliverLanding">Delivery&nbsp;Partner</a>
                </div>
              </li> -->
              <!-- <li class="sellerLog">
                            <div class="btn-group currencies-block">
                                <a @click="merchantLanding"> Sell&nbsp;on&nbsp;Cochchi</a>
                            </div>                              
              </li>-->
              <li class="sellerLog hidden-sm hidden-xs">
                <div class="btn-group currencies-block home-nav">
                  <a @click="merchantLandingnew">Sell&nbsp;on&nbsp;Cochchi</a>
                </div>
              </li>
              <li class="sellerLog" v-if="this.$store.state.buyer_accessToken == null">
                <div class="btn-group currencies-block home-nav">
                    <a v-on:mouseup="loginNew(1)"><b> Login </b></a><b>|</b>
                    <a v-on:mouseup="loginNew(2)"><b> Signup</b></a>
                </div>
              </li>
              <li v-if="this.$store.state.buyer_accessToken !== null">
                <div class="btn-group currencies-block home-nav">
                  <a @click="handleProfile"><b><i class="fa fa-user" aria-hidden="true"></i> {{buyer}}</b></a>
                </div>
                  <ul id="newloggedinmain-ul">
                    <li class="list-group-item" v-if="userRole == 1"><a @click="handleProfile"><i class="fa fa-user" aria-hidden="true"></i> Profile</a></li>
                    <li class="list-group-item"><a @click="myOrders"><i class="fa fa-shopping-cart" aria-hidden="true"></i> My Orders</a></li>
                    <li class="list-group-item"><a @click="wishlist"><i class="fa fa-heart" aria-hidden="true"></i> Wishlist</a></li>
                    <li class="list-group-item"><a @click="disputeReturn"><i class="fa fa-undo" aria-hidden="true"></i> Dispute & Return</a></li>
                    <li class="list-group-item"><a @click="myReviews"><i class="fa fa-paper-plane" aria-hidden="true"></i> My Reviews</a></li>
                    <li class="list-group-item"><a @click="myCoupons"><i class="fa fa fa-ticket" aria-hidden="true"></i> My Coupons</a></li>
                    <li class="list-group-item"><a @click="myVouchers"><i class="fa fa fa-ticket" aria-hidden="true"></i> My Voucher</a></li>
                  </ul>
              </li>
              <li v-if="this.$store.state.buyer_accessToken !== null">
                <div class="btn-group currencies-block home-nav">
                  <a @click="logout"><b><i class="fa fa-unlock" aria-hidden="true"></i> Logout</b></a>
                </div>
              </li>
              <!-- <li class="currency hidden-sm hidden-xs">
                <div class="btn-group currencies-block">
                  <form
                    action="index.html"
                    method="post"
                    enctype="multipart/form-data"
                    id="currency"
                  >
                    <a
                      class="btn btn-link dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <span class="icon icon-credit"></span
                      >&nbsp;&nbsp;LKR&nbsp;&nbsp;
                      <span class="fa fa-angle-down"></span>
                    </a>
                    <ul class="dropdown-menu btn-xs">
                      <li>
                        <a @click="home">Sri Lankan Rupees - LKR</a>
                      </li>
                    </ul>
                  </form>
                </div>
              </li> -->
              <!-- <li class="language hidden-sm hidden-xs">
                <div class="btn-group languages-block">
                  <form
                    action="index.html"
                    method="post"
                    enctype="multipart/form-data"
                    id="bt-language"
                  >
                    <a
                      class="btn btn-link dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <img
                        src="assets/image/catalog/flags/gb.png"
                        alt="English"
                        title="English"
                      />
                      <span class>&nbsp;&nbsp;English&nbsp;&nbsp;</span>
                      <span class="fa fa-angle-down"></span>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a @click="home">
                          <img
                            class="image_flag"
                            src="assets/image/catalog/flags/gb.png"
                            alt="English"
                            title="English"
                          />&nbsp;&nbsp;English
                        </a>
                      </li>
                    </ul>
                  </form>
                </div>
              </li> -->
            </ul>
          </div>

          <!-- New buyer header link area starts - 26-10-2020 -->
          <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 newbuyerlgn-linkarea">
              <ul>
                  <li v-if="this.$store.state.buyer_accessToken == null"><a v-on:mouseup="loginNew"><b> Login | Signup</b></a></li>
                  <li v-if="this.$store.state.buyer_accessToken !== null">
                    <a @click="handleProfile"><b><i class="fa fa-user" style="font-size: 14px;margin-right: 6px;" ></i> {{buyer}}</b></a>
                        <ul id="newloggedinmain-ul">
                          <li class="list-group-item" v-if="userRole == 1"><a @click="handleProfile">Profile</a></li>
                          <li class="list-group-item"><a @click="myOrders">My Orders</a></li>
                          <li class="list-group-item"><a @click="wishlist">Wishlist</a></li>
                          <li class="list-group-item"><a @click="disputeReturn">Dispute & Return</a></li>
                          <li class="list-group-item"><a @click="myReviews">My Reviews</a></li>
                        </ul>
                  </li>
                  <li v-if="this.$store.state.buyer_accessToken !== null"><a @click="logout"><b><i class="fa fa-unlock text-color-headder" style="font-size: 14px;margin-right: 6px;" ></i> Logout</b></a></li>
              </ul>
          </div>  -->
          <!-- New buyer header link area ends - 26-10-2020 -->


        </div>
      </div>
    </div>



    <!-- Delivery partner & sell on cochchi area ends -->


     <!-- Header footer start -->
    <!-- <div class="header-top hidden-compact" style="min-height: 0px;">
      <div class="container">
        <div class="row">
          <div class="bottom1 menu-vertical col-lg-2 col-md-3 col-sm-3">
            <div class="hidden-md hidden-sm hidden-xs welcome-msg">
 
              <div class="responsive so-megamenu megamenu-style-dev">
              <div class="so-vertical-menu">
                <nav class="navbar-default">
                  <div class="container-megamenu vertical">
                    <div id="menuHeading">
                      <div class="megamenuToogle-wrapper">
                        <div class="megamenuToogle-pattern">
                          <div
                            class="container"
                            style="border-top-right-radius: 5px;border-bottom-right-radius: 5px;text-align: center;padding: 0px;margin: 0px;"
                          >
                            Categories
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
              </div>

            </div>
          </div>

          <div class="bottom1 menu-vertical col-lg-10 col-md-9 col-sm-9">
            <nav>
              <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                <a class="navbar-brand" href="#">HOME</a>
                <a class="navbar-brand" href="#">FEATURES</a>
                <a class="navbar-brand" href="#">PAGES</a>
                <a class="navbar-brand" href="#">CONTACT US</a>
                <a class="navbar-brand" href="#">ACCESSORIES</a>
                <a class="navbar-brand" href="#">BLOG</a>
              </div>
            </nav>
          </div>

        </div>
      </div>
    </div> -->
     <!-- Header footer ends -->
       

    <div class="header-bottom hidden-compact">
      <div class="container">
        <div class="row bottom-row">
          <div class="bottom1 menu-vertical buyer-prof-menu-vertical col-lg-2 col-md-2 col-sm-2 col-xs-2">
            <div class="responsive so-megamenu megamenu-style-dev">
              <div class="so-vertical-menu">
                <nav class="navbar-default">
                  <div class="container-megamenu vertical">
                    <div id="menuHeading">
                      <div class="megamenuToogle-wrapper">
                        <div class="megamenuToogle-pattern" style="margin-top: 2px;">
                          <div
                            class="container cat-list-tab"
                            style="border-top-right-radius: 5px;border-bottom-right-radius: 5px;text-align: left;padding: 0px;margin: 0px;padding-left: 10px;padding-right: 10px;"
                          >
                            <i class="fa fa-bars cat-list-icon" aria-hidden="true"></i>
                            Categories
                            <i class="fa fa-chevron-down cat-list-arrow" aria-hidden="true"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="navbar-header"
                      style="padding-bottom: 17px; border-radius: 5px;"
                    >
                      <div class="dropdown">
                        <!-- <button
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          class="navbar-toggle dropdown-toggle"
                        >
                          <i class="fa fa-bars"></i>&nbsp;
                          <span>All Categories</span>
                        </button>-->
                        <button
                          type="button"
                          id="show-verticalmenu"
                          data-toggle="collapse"
                          class="navbar-toggle"
                        >
                          <i class="fa fa-bars"></i>
                          <span>&nbsp; All Categories</span>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                          style="right: auto;"
                        >
                          <div style="width: 200px;display: grid;">
                            <a
                              v-for="mcat in mainCategory"
                              v-bind:key="mcat._id"
                              :value="mcat._id"
                              @click="pushMobileSearch(mcat._id)"
                              class="dropdown-item"
                              href="#"
                              >{{ mcat.category_name }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="vertical-wrapper"
                      style="margin-top: 2px; border-radius: 10px;"
                    >
                      <span id="remove-verticalmenu" class="fa fa-times"></span>
                      <div class="megamenu-pattern">
                        <div class="container-mega">
                          <ul
                            class="megamenu"
                            style="display: contents;"
                            @mouseover="mouseOverMenu"
                          >
                            <li
                              style="border-bottom: 1px solid #d3d3d3;"
                              v-for="(mcat1, index) in mainCategory"
                              v-bind:key="mcat1._id"
                              :style="[
                                mainCategory.length - 1 === index
                                  ? {
                                      'border-bottom-left-radius': '5px',
                                      'border-bottom-right-radius': '5px',
                                    }
                                  : '',
                                index === 0
                                  ? {
                                      'border-top-left-radius': '5px',
                                      'border-top-right-radius': '5px',
                                    }
                                  : '',
                              ]"
                              :class="
                                mcat1.sub_category_one.length > 0
                                  ? 'with-sub-menu'
                                  : ''
                              "
                              class="item-vertical hover"
                            >
                              <p class="close-menu"></p>
                              <a
                                @click.prevent.stop="
                                  pushTocategoryMain(
                                    mcat1._id,
                                    mcat1.category_name
                                  )
                                "
                                class="cat-menusub clearfix"
                                style="padding: 8px 0;"
                              >
                                <li
                                  class="fa fa-user"
                                  v-bind:class="mcat1.icon"
                                ></li>
                                <span style="margin-left:5px;">{{
                                  mcat1.category_name
                                }}</span>
                                <b
                                  class="fa fa-angle-right"
                                  v-if="mcat1.sub_category_one.length > 0"
                                ></b>
                              </a>
                              <div
                                class="sub-menu"
                                data-subwidth="60"
                                style="width: max-content !important;"
                                v-if="mcat1.sub_category_one.length > 0"
                              >
                               <div
                                  class="content"
                                  id="sub-menu-div"
                                >
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div class="row">
                                        <div
                                          v-for="cat in mcat1.sub_category_one"
                                          v-bind:key="cat._id"
                                           :class="
                                            mcat1.sub_category_one.length < 3
                                              ? 'col-md-6 static-menu'
                                              : 'col-md-4 static-menu'
                                          "
                                        >
                                          <div class="menu">
                                            <ul >
                                              <li>
                                                <a
                                                  @click.prevent.stop="
                                                    pushTocategory(
                                                      cat._id,
                                                      cat.category_name
                                                    )
                                                  "
                                                  class="main-menu"
                                                  >{{ cat.category_name }}</a
                                                >
                                                <ul>
                                                  <li
                                                    v-for="scat in cat.sub_category_two"
                                                    v-bind:key="scat._id"
                                                  >
                                                    <a
                                                      @click.prevent.stop="
                                                        pushToProductList(
                                                          scat._id,
                                                          scat.category_name
                                                        )
                                                      "
                                                    >{{ scat.category_name }}</a>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <!-- <div v-if="menubanner" style="margin-top:15px;">
                            <div>
                              <div>
                                <div class="module banner-left hidden-xs">
                                  <div class="banner-sidebar banners" style="margin-bottom: 10px;">
                                    <div>
                                      <a title="Categories" href="#">
                                        <img
                                          src="assets/image/catalog/banners/banner-sidebar.png"
                                          alt="Banner Image"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div class="banner-sidebar banners">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>

          <!-- <div
            class="col-lg-10 col-md-9 col-sm-9"
            style="margin-bottom: -20px;"
          >
            <div v-if="hideBreadCum === 0">
              <ul class="breadcrumb mer-bc-list">
                <li>
                  <a @click.prevent.stop="breadpush('/', '')">
                    <i class="fa fa-home home-bedcrumb"></i>
                  </a>
                </li>
                <li v-for="(item, index) in breadcrumb" :key="index">
                  <a disabled @click.prevent.stop="breadpush(item, item.id)">
                    {{ item.text }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="responsive so-megamenu megamenu-style-dev">
              <nav class="navbar-default">
                <div class="container-megamenu horizontal open">
                  <div class="navbar-header"> -->
                    <!-- <button
                      type="button"
                      id="show-verticalmenu"
                      data-toggle="dropdown"
                      class="navbar-toggle"
                    >
                      <i class="fa fa-bars"></i>&nbsp;
                      <span>MENU</span>
                    </button>-->
                    <!-- <ul class="dropdown-menu menu-profile">
                      <li>
                        <a class="clearfix" @click="handdleClickFlashDeals"
                          >&nbsp;&nbsp;ALL PRODUCTS</a
                        >
                      </li>
                    </ul>
                    <div class="shopcart-inner">
                      <p class="text-shopping-cart"></p>
                    </div>
                  </div>
                  <div class="megamenu-wrapper">
                    <span id="remove-megamenu" class="fa fa-times"></span>
                    <div class="megamenu-pattern">
                      <div class="container-mega">
                        <ul
                          class="megamenu"
                          data-transition="slide"
                          data-animationtime="250"
                        >
                          <li>
                            <p class="close-menu"></p>
                            <a
                              @click="handdleClickSathosa"
                              class="clearfix"
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div> -->

          <!-- Home page menu -->
          <div class="col-lg-10 col-md-9 col-sm-9 home-page-menu">
              <ul>
                <li><router-link style="color: white" to="/" exact>Home</router-link></li>
                <!-- <li><router-link style="color: white" to="/subscribe"  exact>Features</router-link></li> -->
                <!-- <li><router-link to="/subscribe"  exact>Features <i class="fa fa-caret-down" aria-hidden="true"></i></router-link></li> -->
                <!-- <li><router-link style="color: white" to="/subscribe"  exact>Pages</router-link></li> -->
                <!-- <li><router-link style="color: white" to="/subscribe"  exact>Accessories</router-link></li> -->
                <li><router-link style="color: white" to="/subscribe"  exact>Blog</router-link></li>
                <li><router-link style="color: white" to="/ETeleshop"  exact>E-Teleshop</router-link></li>
                <li id="madeSLLi"><a @click="haddleMadeInSrilanka()">  <img src="https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1616564808/cochchi/portal_user/1616564806917.png" alt=""></a></li>
              </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-offset-3 col-md-offset-3 col-sm-offset-3 col-xs-offset-3 breadcum-content-block" v-if="this.$route.name=='Product List'">
      <div
            class="col-lg-10 col-md-9 col-sm-9"
            style="margin-bottom: -20px;"
          >
            <div v-if="hideBreadCum === 0">
              <ul class="breadcrumb mer-bc-list">
                <li>
                  <a @click.prevent.stop="breadpush('/', '')">
                    <i class="fa fa-home home-bedcrumb"></i>
                  </a>
                </li>
                <li v-for="(item, index) in breadcrumb" :key="index">
                  <a disabled @click.prevent.stop="breadpush(item, item.id)">
                    {{ item.text }}
                  </a>
                </li>
              </ul>
              <!-- {{breadcrumb}} -->
            </div>
            <div class="responsive so-megamenu megamenu-style-dev">
              <nav class="navbar-default">
                <div class="container-megamenu horizontal open">
                  <div class="navbar-header">
                    <!-- <button
                      type="button"
                      id="show-verticalmenu"
                      data-toggle="dropdown"
                      class="navbar-toggle"
                    >
                      <i class="fa fa-bars"></i>&nbsp;
                      <span>MENU</span>
                    </button>-->
                    <ul class="dropdown-menu menu-profile">
                      <li>
                        <a class="clearfix" @click="handdleClickFlashDeals"
                          >&nbsp;&nbsp;ALL PRODUCTS</a
                        >
                      </li>
                    </ul>
                    <div class="shopcart-inner">
                      <p class="text-shopping-cart"></p>
                    </div>
                  </div>
                  <div class="megamenu-wrapper">
                    <span id="remove-megamenu" class="fa fa-times"></span>
                    <div class="megamenu-pattern">
                      <div class="container-mega">
                        <ul
                          class="megamenu"
                          data-transition="slide"
                          data-animationtime="250"
                        >
                          <li>
                            <p class="close-menu"></p>
                            <a
                              @click="handdleClickSathosa"
                              class="clearfix"
                            ></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>

    </div>


    <BuyerLoginModal ref="active" />

    <!-- Track Order Modal -->

    <div
      class="modal fade"
      id="mdlTrackOrder"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Track My Order</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input
              style="margin-top: 10px;"
              v-model="trackid"
              type="text"
              class="form-control"
              id="input-store"
              placeholder="Order ID"
              value
              name="voucher"
            />
            <br />
            <h4 style="color: red;">{{ trackmessage }}</h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              @click="trackMyOrder()"
              style="border: #ffd338; background: #ffd338; color: black;"
              class="btn btn-primary"
            >
              Track
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinCategory from "@/mixins/APICommon";
import mixinProduct from "@/mixins/buyer/APIProduct";
import mixinOrder from "@/mixins/buyer/APIOrder";
import mixinProfile from "../../mixins/buyer/APIProfile";
import BuyerLoginModal from "@/views/Buyer/BuyerLogin/BuyerLloginModal.vue";
import { functions } from "firebase";

export default {
  name: "TopHeader",
  components: { BuyerLoginModal },
  mixins: [mixinCategory, mixinProduct, mixinProfile, mixinOrder],
  data() {
    return {
      homeTabData: [],
      userRole: 1,
      breadcrumb: [
        {
          disabled: true,
          text: "",
          active: true,
        },
        {
          disabled: true,
          text: "",
          active: false,
        },
      ],
      hideBreadCum: 0,
      credit: "",
      buyer: "",
      ProfileLbl: "Profile",
      loggedin: false,
      menuloaded: false,
      mainCategory: [],
      no_of_cart_items: 0,
      cart_products: [],
      image: "",
      idObj: {
        ids: [],
      },
      removeItems: [],
      searchtxt: "",
      cid: "",
      searchdisable: true,
      trackid: "",
      trackmessage: "",
      BuyerData: {},
      menubanner: true
    };
  },
  computed: {
    contactImage: function() {
      if (this.image) {
        return this.image;
      } else {
        return "assets/image/profile/default_profile_image.jpg";
      }
    },
    BuyerDataStore: function() {
      return this.$store.state.buyer_basic_info.buyer_basic_info.payload;
    },
    addToCartObj: {
      get() {
        return this.$store.state.cart.cart.payload;
      },
    },
    currentRouteName() {
        return this.$route.name;
    }
  },
  watch: {
    addToCartObj: {
      handler: function(val) {
        this.setCartItemsStore(val);
      },
    },
    $route(to, from) {
      this.getLoginInfo();
      this.setCartItems();
      this.menuBannerStatus();
    },
    "$store.state.buyer_full_name"() {
      if (
        this.$store.state.buyer_full_name.first_name === null &&
        this.$store.state.buyer_full_name.last_name === null
      ) {
        this.getLoginInfo();
      } else {
        this.buyer =
          this.$store.state.buyer_full_name.first_name +
          " " +
          this.$store.state.buyer_full_name.last_name;
      }
      this.setCartItems();
      this.init();
    },
    "$route.name"() {
      // console.log(this.$route.name);
      this.setBreadCum();
    },
    "$route.query.pid"() {
      this.setBreadCum();
    },
    "$route.query.id"() {
      this.setBreadCum();
    },
    "$store.state.buyer_accessToken"() {
      this.getLoginInfo();
    },
  },
  beforemounted() {
    this.init();
    this.menuBannerStatus();
    // var itemver = 11;
    // var show_itemver = itemver - 1;

    var active = false;
    var hover = false;
    var itemver = 11;
    var show_itemver = itemver - 1;
    $(document).ready(function() {
      $(".vertical .megamenu .loadmore").click(function() {
        if ($(this).hasClass("open")) {
          $("ul.megamenu li.item-vertical").each(function(i) {
            if (i > show_itemver) {
              $(this).slideUp(200);
              $(this).css("display", "none");
            }
          });
          $(this).removeClass("open");
          $(".vertical .megamenu .loadmore").html(
            '<i class="fa fa-plus-square"></i><span class="more-view">Open Categories</span>'
          );
        } else {
          $(".vertical ul.megamenu li.item-vertical").each(function(i) {
            if (i > show_itemver) {
              $(this).slideDown(200);
            }
          });
          $(this).addClass("open");
          $(".vertical .megamenu .loadmore").html(
            '<i class="fa fa-minus-square"></i><span class="more-view">Close Categories</span>'
          );
        }
      });
   
   
      $("ul.megamenu li .sub-menu .content .hover-menu ul li").hover(
        function() {
          $(this)
            .children("ul")
            .show();
        },
        function() {
          $(this)
            .children("ul")
            .hide();

        }
      );


      var wd_width = $(window).width();
      if (wd_width <= 991) {
        $("ul.megamenu > li.hover").unbind("mouseenter mouseleave");
        removeWidthSubmenu();
        clickMegaMenu();
      } else {
        $("ul.megamenu > li.hover").unbind("click");
        hoverMegaMenu();
        renderWidthSubmenu();
      }

      $(window).resize(function() {
        var sp_width = $(window).width();
        if (sp_width <= 991) {
          $("ul.megamenu > li.hover").unbind("mouseenter mouseleave");
          removeWidthSubmenu();
          clickMegaMenu();
        } else {
          $("ul.megamenu > li.hover").unbind("click");
          hoverMegaMenu();
          renderWidthSubmenu();
        }
      });

      $("ul.megamenu > li.click").click(function() {
        if (
          $(this)
            .find(".content")
            .is(":visible")
        ) {
          return false;
        }
        active = $(this);
        hover = true;
        var transition = $(this)
          .closest(".megamenu")
          .data("transition");
        var animation_time = $(this)
          .closest(".megamenu")
          .data("animationtime");
        $("ul.megamenu > li").removeClass("active");
        $(this).addClass("active");
        $("ul.megamenu > li")
          .children(".sub-menu")
          .hide();
        $("ul.megamenu > li")
          .find(".content")
          .hide();
        $(this)
          .children(".sub-menu")
          .show();
        if (transition == "slide") {
          $(this)
            .find(".content")
            .show();
        } else if (transition == "fade") {
          $(this)
            .find(".content")
            .fadeIn(animation_time);
        } else {
          $(this)
            .find(".content")
            .show();
        }
        $(this)
          .children(".sub-menu")
          .css("right", "auto");
        if (
          $("html")
            .css("direction")
            .toLowerCase() == "rtl"
        ) {
          var $whatever = $(this).children(".sub-menu");
          var $whatever2 = $($whatever).closest("ul.megamenu");
          if ($whatever.offset().left < $whatever2.offset().left) {
            $(this)
              .children(".sub-menu")
              .css("right", "0");
          }
        } else {
          var $whatever = $(this).children(".sub-menu");
          var ending_right =
            $(window).width() -
            ($whatever.offset().left + $whatever.outerWidth());
          var $whatever2 = $($whatever).closest("ul.megamenu");
          var ending_right2 =
            $(window).width() -
            ($whatever2.offset().left + $whatever2.outerWidth());
          if (ending_right2 > ending_right) {
            $(this)
              .children(".sub-menu")
              .css("right", "0");
          }
        }

        return false;
      });

      $("#show-megamenu").click(function() {
        if ($(".megamenu-wrapper").hasClass("so-megamenu-active"))
          $(".megamenu-wrapper").removeClass("so-megamenu-active");
        else $(".megamenu-wrapper").addClass("so-megamenu-active");
      });
      $("#remove-megamenu").click(function() {
        $(".megamenu-wrapper").removeClass("so-megamenu-active");
        return false;
      });

      $("#show-verticalmenu").click(function() {
        if ($(".vertical-wrapper").hasClass("so-vertical-active"))
          $(".vertical-wrapper").removeClass("so-vertical-active");
        else $(".vertical-wrapper").addClass("so-vertical-active");
      });
      $("#remove-verticalmenu").click(function() {
        $(".vertical-wrapper").removeClass("so-vertical-active");
        return false;
      });

      $("html").on("click", function() {
        $("ul.megamenu > li.click").removeClass("active");
        $("ul.megamenu > li.click")
          .children(".sub-menu")
          .hide();
        $("ul.megamenu > li.click")
          .find(".content")
          .hide();
      });
      $(".close-menu").on("click", function() {
        $(this)
          .parent()
          .removeClass("active");
        $(this)
          .parent()
          .children(".sub-menu")
          .hide();
        $(this)
          .parent()
          .find(".content")
          .hide();
        return false;
      });
    });

    // Buyer name clicked dropdown starts-commented on 27-10-2020
    // const newLoggedInUlEl = document.querySelector("#newloggedinmain-ul");
    // var  newLoggedInUlLists = newLoggedInUlEl.querySelectorAll("li");
    // var listCount = newLoggedInUlLists.length;
    // newLoggedInUlLists.forEach((el, index)=>{
    //   if(parseInt(index+1)%2){
    //       el.style.background = "#f7f5f5";
    //   }else{
    //       el.style.background = "#ffffff";
    //   }
    // });
    // Buyer name clicked dropdown ends

    function renderWidthSubmenu() {
      $(".vertical .sub-menu").each(function() {
        value = $(this).data("subwidth");
        if (value) {
          var container_width = $(".container").width();
          var vertical_width = $(".vertical").width();
          var full_width = container_width - vertical_width;
          var width_submenu = (full_width * value) / 100;
          $(this).css("width", width_submenu + "px");
        }
      });
    }
    function removeWidthSubmenu() {
      $(".vertical .sub-menu").each(function() {
        $(this).css("width", "100%");
      });
    }
    function clickMegaMenu() {
      $("ul.megamenu > li.hover").click(function() {
        if (
          $(this)
            .find(".content")
            .is(":visible")
        ) {
          return true;
        }
        active = $(this);
        hover = true;
        var transition = $(this)
          .closest(".megamenu")
          .data("transition");
        var animation_time = $(this)
          .closest(".megamenu")
          .data("animationtime");
        $("ul.megamenu > li").removeClass("active");
        $(this).addClass("active");
        $("ul.megamenu > li")
          .children(".sub-menu")
          .hide();
        $("ul.megamenu > li")
          .find(".content")
          .hide();
        $(this)
          .children(".sub-menu")
          .show();
        if (transition == "slide") {
          $(this)
            .find(".content")
            .show();
          $(this)
            .find(".content")
            .css("height", "auto");
          var originalHeight = $(this)
            .find(".content")
            .height();
        } else if (transition == "fade") {
          $(this)
            .find(".content")
            .fadeIn(animation_time);
        } else {
          $(this)
            .find(".content")
            .show();
        }
        $(this)
          .children(".sub-menu")
          .css("right", "auto");
        if (
          $("html")
            .css("direction")
            .toLowerCase() == "rtl"
        ) {
          var $whatever = $(this).children(".sub-menu");
          var $whatever2 = $($whatever).closest("ul.megamenu");
          if ($whatever.offset().left < $whatever2.offset().left) {
            $(this)
              .children(".sub-menu")
              .css("right", "0");
          }
        } else {
          var $whatever = $(this).children(".sub-menu");
          var ending_right =
            $(window).width() -
            ($whatever.offset().left + $whatever.outerWidth());
          var $whatever2 = $($whatever).closest("ul.megamenu");
          var ending_right2 =
            $(window).width() -
            ($whatever2.offset().left + $whatever2.outerWidth());
          if (ending_right2 > ending_right) {
            $(this)
              .children(".sub-menu")
              .css("right", "0");
          }
        }
      });
    }

    function hoverMegaMenu() {
      $("ul.megamenu > li.hover").hover(
        function() {
          active = $(this);
          hover = true;
          var transition = $(this)
            .closest(".megamenu")
            .data("transition");
          var animation_time = $(this)
            .closest(".megamenu")
            .data("animationtime");
          $("ul.megamenu > li").removeClass("active");
          $(this).addClass("active");
          $("ul.megamenu > li")
            .children(".sub-menu")
            .hide();
          $("ul.megamenu > li")
            .find(".content")
            .hide();
          $(this)
            .children(".sub-menu")
            .show();
          if (transition == "slide") {
            $(this)
              .find(".content")
              .show();
          } else if (transition == "fade") {
            $(this)
              .find(".content")
              .fadeIn(animation_time);
          } else {
            $(this)
              .find(".content")
              .show();
          }
          $(this)
            .children(".sub-menu")
            .css("right", "auto");
          if (
            $("html")
              .css("direction")
              .toLowerCase() == "rtl"
          ) {
            var $whatever = $(this).children(".sub-menu");
            var $whatever2 = $($whatever).closest("ul.megamenu");
            if ($whatever.offset().left < $whatever2.offset().left) {
              $(this)
                .children(".sub-menu")
                .css("right", "0");
            }
          } else {
            var $whatever = $(this).children(".sub-menu");
            var ending_right =
              $(window).width() -
              ($whatever.offset().left + $whatever.outerWidth());
            var $whatever2 = $($whatever).closest("ul.megamenu");
            var ending_right2 =
              $(window).width() -
              ($whatever2.offset().left + $whatever2.outerWidth());
            if (ending_right2 > ending_right) {
              $(this)
                .children(".sub-menu")
                .css("right", "0");
            }
          }
        },
        function() {
          var rel = $(this).attr("title");
          hover = false;
          var transition = $(this)
            .closest(".megamenu")
            .data("transition");
          var animation_time = $(this)
            .closest(".megamenu")
            .data("animationtime");
          if (rel == "hover-intent") {
            var hoverintent = $(this);
            setTimeout(function() {
              if (hover == false) {
                if (transition == "slide") {
                  $(hoverintent)
                    .find(".content")
                    .stop(true, true)
                    .animate({ height: "hide" }, animation_time, function() {
                      if (hover == false) {
                        $(hoverintent).removeClass("active");
                        $(hoverintent)
                          .children(".sub-menu")
                          .hide();
                      }
                    });
                } else if (transition == "fade") {
                  $(hoverintent).removeClass("active");
                  $(hoverintent)
                    .find(".content")
                    .fadeOut(animation_time, function() {
                      if (hover == false) {
                        $(hoverintent)
                          .children(".sub-menu")
                          .hide();
                      }
                    });
                } else {
                  $(hoverintent).removeClass("active");
                  $(hoverintent)
                    .children(".sub-menu")
                    .hide();
                  $(hoverintent)
                    .find(".content")
                    .hide();
                }
              }
            }, 500);
          } else {
            if (transition == "slide") {
              $(this)
                .find(".content")
                .stop(true, true)
                .animate({ height: "hide" }, animation_time, function() {
                  if (hover == false) {
                    $(active).removeClass("active");
                    $(active)
                      .children(".sub-menu")
                      .hide();
                  }
                });
            } else if (transition == "fade") {
              $(active).removeClass("active");
              $(this)
                .find(".content")
                .fadeOut(animation_time, function() {
                  if (hover == false) {
                    $(active)
                      .children(".sub-menu")
                      .hide();
                  }
                });
            } else {
              $(this).removeClass("active");
              $(this)
                .children(".sub-menu")
                .hide();
              $(this)
                .find(".content")
                .hide();
            }
          }
        }
      );
    }

    // $(document).ready(function(){}
  },
  created() {
    this.handleHeaderTabs();
    // this.handdleCheckCodeBasedAuthentication();
    this.hideProfileLbl();
    this.getCategories();
    this.setCartItems();
    this.getLoginInfo();
    this.init();
    this.setBreadCum();
  },
  methods: {
    homeTabclick(id) {
      this.$router.push({ name: "Product List Home Tabs", query: { id: id } });
    },
    handleHeaderTabs: async function() {
      try {
        let response = await this.getHomeTabProducts();

        var currentLogoSectionHeight = document.getElementById("homelogosection")
          .offsetHeight;
        var searchSectionHeight = document.getElementById("commn-search-col")
          .offsetHeight;
        // var homeTabHeight = document.getElementById("hometabs").offsetHeight;
        var homeTabHeight = 0;

        var remainingHeight =
          currentLogoSectionHeight - (searchSectionHeight + homeTabHeight);

        // document.getElementById("hometabs").style.paddingTop =
        //   remainingHeight + "px";

        this.homeTabData = response;
      } catch (error) {
        console.log(error);
      }
    },
    setBreadCum: async function() {
      if (this.$route.name === "Product List") {
        this.hideBreadCum = 0;
        this.breadcrumb[0].text = "";
        this.breadcrumb[0].id = "";
        this.breadcrumb[0].href = "";
        this.breadcrumb[1].text = "";
        this.breadcrumb[1].id = "";
        this.breadcrumb[1].href = "";
        try {
          let result = [];
          let result1 = [];
          let status = await this.getMainCategories();
         
          for (let x = 0; x < status.length; x++) {
            for (let c = 0; c < status[x].sub_category_one.length; c++) {
              for (
                let v = 0;
                v < status[x].sub_category_one[c].sub_category_two.length;
                v++
              ) {
                 
                // Level 03
                if (
                  this.$route.query.id ===
                  status[x].sub_category_one[c].sub_category_two[v]._id
                ) {
                  result = status[x].sub_category_one[c];
                  result1 = status[x].sub_category_one[c].sub_category_two[v];
                }
               
                // Level 02
                if (
                  this.$route.query.id ===
                  status[x].sub_category_one[c]._id
                ) {
                  result = status[x];
                  result1 = status[x].sub_category_one[c];
                }
               
                // Level 01
                if (
                  this.$route.query.id ===
                  status[x]._id
                ) {
                  result = status[x];
                }
              }
            }
          }
         
          this.breadcrumb[0].text = result.category_name;
          this.breadcrumb[0].id = result._id;
          this.breadcrumb[0].href = "Product List";
          this.breadcrumb[1].text = result1.category_name;
          this.breadcrumb[1].id = result1._id;
          this.breadcrumb[1].href = "Product List";
        } catch (error) {
          throw error;
        }
      } else if (this.$route.name === "product") {
        this.hideBreadCum = 0;
        this.breadcrumb[0].text = "";
        this.breadcrumb[0].id = "";
        this.breadcrumb[0].href = "";
        this.breadcrumb[1].text = "";
        this.breadcrumb[1].id = "";
        this.breadcrumb[1].href = "";
        try {
          let response = await this.getProductInfo(this.$route.query.pid);
          this.$store.commit(
            "updateCategoryName",
            response.product.category.category_name
          );
          localStorage.setItem(
            "categoryName",
            response.product.category.category_name
          );
          this.breadcrumb[0].text = response.breadcrumbs[1].category_name;
          this.breadcrumb[0].id = response.breadcrumbs[1]._id;
          this.breadcrumb[0].href = "Product List Main";
          this.breadcrumb[1].text = response.product.category.category_name;
          this.breadcrumb[1].id = response.product.category._id;
          this.breadcrumb[1].href = "Product List";
        } catch (error) {
          throw error;
        }
      } else {
        this.breadcrumb[0].text = "";
        this.breadcrumb[0].id = "";
        this.breadcrumb[0].href = "";
        this.breadcrumb[1].text = "";
        this.breadcrumb[1].id = "";
        this.breadcrumb[1].href = "";
        this.hideBreadCum = 1;
      }
    },
    breadpush(pathlink, id) {
      if (id === "") {
        this.$router.push(pathlink) ;
      } else {
        this.$router
          .push({ name: pathlink.href, query: { id: id } })
           ;
      }
    },
    menuBannerStatus() {
      if (!document.body.classList.contains("common-home")) {
        this.menubanner = false;
      } else {
        this.menubanner = true;
      }
    },
    handdleClickSathosa: function() {
      this.$router.push("productsearch") ;
    },
    // check url code based authentication and save token in local storage - deepal
    handdleCheckCodeBasedAuthentication: async function() {
      var user_id = getUrlParameterByName("auth_user_id");
      var code = getUrlParameterByName("auth_code");
      if (user_id != null && code != null) {
        try {
          // alert(user_id+' / '+code);
          let response = await this.getCodeBasedAuthentication(user_id, code);
          localStorage.setItem("buyer_token", response.data.token);
          localStorage.setItem("buyer_type", "buyer");
          localStorage.setItem("login_buyer", response.data._id);
          localStorage.setItem("buyer_data", JSON.stringify(response.data));
          this.$store.dispatch("doLoginbuyer");
          this.$swal.fire({
            position: "top-right",
            type: "success",
            title: "Logged In!",
            showConfirmButton: false,
            timer: 1500,
          });
          var url = window.location.href;
          var urlarr = url.split("/");
          var hostname = urlarr[0] + "//" + urlarr[2];
          window.location.replace(hostname);
        } catch (error) {
          console.log(error);
        }
      }
    },
    // getProfileData: async function() {
    //     try {
    //         let response = await this.getProfileBacicInfo();
    //         this.buyer = response.first_name + " " + response.last_name;
    //     } catch (error) {
    //         throw error;
    //     }
    // },
    getUserCreditLimit: async function() {
      try {
        let response = await this.getCreditLimit();
        if (response.success == 1) {
          this.credit = response.credit;
        } else {
          this.credit = "";
        }
      } catch (error) {
        this.credit = "";
      }
    },
    hideProfileLbl() {
      if (this.buyer) {
        // document.getElementById("profileLbl").style.display = "none";
      }
    },
    init() {
      if (localStorage.getItem("buyer_data")) {
        let user = "";
        user = JSON.parse(localStorage.getItem("buyer_data"));
        if (user.first_name !== null && user.last_name !== null) {
          this.buyer = user.first_name + " " + user.last_name;
        }
        this.userRole = user.user_role != undefined ? user.user_role : "";
      } else {
        this.getLoginInfo();
      }
      // let user = "";
      // user = JSON.parse(localStorage.getItem("buyer_data"));
      // if (user.first_name !== null && user.last_name !== null) {
      //     this.buyer = user.first_name + " " + user.last_name;
      // } else {
      //     this.getLoginInfo();
      // }
    },
    clearmsg() {
      this.trackid = "";
      this.trackmessage = "";
    },
    trackMyOrder: async function() {
      if (this.trackid === "") {
        this.trackmessage = "Enter Order ID";
        return;
      }

      try {
        let response = await this.getOrderList();

        response.forEach((element) => {
          if (element.order_env_number === this.trackid) {
            $("#mdlTrackOrder").modal("hide");
            this.trackid = "";
            this.trackmessage = "";
            this.$router
              .push("/buyer-order-configure/" + element._id)
               ;
            return;
          }
        });

        this.trackmessage = "Order Not Found!";
      } catch (error) {
        throw error;
      }
    },
    validateSearch() {
      if (this.cid === "" && this.searchtxt === "") {
        this.searchdisable = true;
      } else {
        this.searchdisable = false;
      }
    },
    pushSearch() {
      let params = "";
      if (this.searchtxt !== "") {
        params += "&name=" + this.searchtxt;
      }

      if (this.cid !== "") {
        params += "&id=" + this.cid;
      }
      this.$router.push("/productsearch?" + params) ;
      this.searchtxt = "";
    },
    pushMobileSearch(id) {
      let params = "";
      if (id !== "") {
        params += "&id=" + id;
      }
      this.$router.push("/productsearch?" + params) ;
    },
    logToCart() {
      if (this.no_of_cart_items > 0) {
        this.$router.push("/buyer-cart") ;
      }
    },
    loginNew(num) {
      this.$refs.active.activetab = num;
      $("#BuyerLoginModal").modal("show");
      if (this.$refs.active.showforgotpass === true) {
        // hide forget password modal when login
        this.$refs.active.showforgotpass = false;
      }
    },
    myOrders() {
      this.$router.push("buyer-orders") ;
    },
    DisputeReturn() {
      this.$router.push("buyer-return-orders") ;
    },
    BuyerMyReviews() {
      this.$router.push("buyer-my-reviews") ;
    },
    myAccount() {
      this.$router.push("me");
    },
    home() {
      this.$router.push("/");
    },
    cart() {
      this.$router.push("buyer-cart");
    },
    wishlist() {
      this.$router.push("buyer-wishlist");
    },
    disputeReturn() {
      this.$router.push("/buyer-return-orders");
    },
    myReviews() {
      this.$router.push("/buyer-my-reviews");
    },
    myCoupons() {
      this.$router.push("/buyer-coupons");
    },
    myVouchers() {
      this.$router.push("/buyer-vouchers");
    },
    login() {
      this.$router.push("/buyer-login");
    },
    logout() {
      // $('BuyerLoginModall').modal('hide');
      this.$router.push("/");

      this.$store.dispatch("logoutbuyer");
      localStorage.removeItem("cart_product_ids");
      localStorage.removeItem("buyer_data");
      localStorage.removeItem("buyer_token");
      localStorage.removeItem("buyer_type");
      localStorage.removeItem("login_buyer");
      localStorage.removeItem("agent_guestdata");
      location.reload(true);
    },
    register() {
      this.$router.push("/buyer-register") ;
    },
    deliverLanding() {
      this.$router.push("/deliver-login") ;
    },
    merchantLanding() {
      this.$router.push("/merchant-landing-new") ;
    },
    merchantLandingnew() {
      this.$router.push("/merchant-landing-new") ;
    },
    handleProfile() {
      if (!localStorage.buyer_data) {
        this.$router.push("/buyer-profile") ;
        return;
      }
      let user = JSON.parse(localStorage.getItem("buyer_data"));
      this.userRole = user.user_role != undefined ? user.user_role : "";
      if (this.userRole == 2) {
        this.$router.push("/buyer-orders") ;
        return;
      } else {
        this.$router.push("/buyer-profile") ;
      }
    },
    viewProfile: function() {
      this.$router.push("/buyer-profile") ;
    },
    getLoginInfo: async function() {
      if (this.$store.state.buyer_accessToken === null) {
        return;
      }

      // this.getUserCreditLimit();
      try {
        let response = await this.getProfileBacicInfo();
        if (response.image) {
          this.image = response.image;
        }
        this.buyer = response.first_name + " " + response.last_name;
      } catch (error) {
        throw error;
      }
    },
    getCategories: async function() {
      try {
        let status = await this.getMainCategories();
        this.mainCategory = status;
        // console.log("this.mainCategory",this.mainCategory)
        // let main_cat = status.sub_category_one;

        // this.mainCategory = status[0].sub_category_one;

        // for (let index = 0; index < this.mainCategory.length; index++) {
        //   const element = this.mainCategory[index];
        //   let new_sub_one = element.sub_category_two;
        //   element.sub_category_one = new_sub_one;
        // }
      } catch (error) {
        throw error;
      }
    },
    pushTocategoryMain(id, str, obj) {
      //Hide Menu
      $(".vertical-wrapper").removeClass("so-vertical-active");
      $(".content")
        .filter(function() {
          return $(this).css("display") == "block";
        })
        .css("display", "none");

      localStorage.setItem("categoryName", str);
      this.$store.commit("updateCategoryName", str);
      this.$router
        .push({ name: "Product List Main", query: { id: id } })
         ;
    },
    pushTocategory(id, str, obj) {
      //Hide Menu
      $(".vertical-wrapper").removeClass("so-vertical-active");
      $(".content")
        .filter(function() {
          return $(this).css("display") == "block";
        })
        .css("display", "none");

      localStorage.setItem("categoryName", str);
      this.$store.commit("updateCategoryName", str);
      this.$router
        .push({ name: "Product List", query: { id: id } })
         ;
    },
    pushToProductList(id, str, obj) {
      localStorage.setItem("categoryName", str);
      this.$store.commit("updateCategoryName", str);

      $(".vertical-wrapper").removeClass("so-vertical-active");
      $(".content")
        .filter(function() {
          return $(this).css("display") == "block";
        })
        .css("display", "none");
      this.$router
        .push({ name: "Product List", query: { id: id } })
         ;
    },
    mouseOverMenu() {
      if (this.menuloaded === false) {
        $(".vertical .sub-menu").each(function() {
          let value = $(this).data("subwidth");
          if (value) {
            var container_width = $(".container").width();
            var vertical_width = $(".vertical").width();
            var full_width = container_width - vertical_width;
            var width_submenu = (full_width * value) / 100;
            $(this).css("width", width_submenu + "px");
          }
        });

        $("ul.megamenu > li.hover").hover(
          function() {
            active = $(this);
            hover = true;
            var transition = $(this)
              .closest(".megamenu")
              .data("transition");
            var animation_time = $(this)
              .closest(".megamenu")
              .data("animationtime");
            $("ul.megamenu > li").removeClass("active");
            $(this).addClass("active");
            $("ul.megamenu > li")
              .children(".sub-menu")
              .hide();
            $("ul.megamenu > li")
              .find(".content")
              .hide();
            $(this)
              .children(".sub-menu")
              .show();
            if (transition == "slide") {
              $(this)
                .find(".content")
                .show();
            } else if (transition == "fade") {
              $(this)
                .find(".content")
                .fadeIn(animation_time);
            } else {
              $(this)
                .find(".content")
                .show();
            }
            $(this)
              .children(".sub-menu")
              .css("right", "auto");
            if (
              $("html")
                .css("direction")
                .toLowerCase() == "rtl"
            ) {
              var $whatever = $(this).children(".sub-menu");
              var $whatever2 = $($whatever).closest("ul.megamenu");
              if ($whatever.offset().left < $whatever2.offset().left) {
                $(this)
                  .children(".sub-menu")
                  .css("right", "0");
              }
            } else {
              var $whatever = $(this).children(".sub-menu");
              var ending_right =
                $(window).width() -
                ($whatever.offset().left + $whatever.outerWidth());
              var $whatever2 = $($whatever).closest("ul.megamenu");
              var ending_right2 =
                $(window).width() -
                ($whatever2.offset().left + $whatever2.outerWidth());
              if (ending_right2 > ending_right) {
                $(this)
                  .children(".sub-menu")
                  .css("right", "0");
              }
            }
          },
          function() {
            var rel = $(this).attr("title");
            hover = false;
            var transition = $(this)
              .closest(".megamenu")
              .data("transition");
            var animation_time = $(this)
              .closest(".megamenu")
              .data("animationtime");
            if (rel == "hover-intent") {
              var hoverintent = $(this);
              setTimeout(function() {
                if (hover == false) {
                  if (transition == "slide") {
                    $(hoverintent)
                      .find(".content")
                      .stop(true, true)
                      .animate({ height: "hide" }, animation_time, function() {
                        if (hover == false) {
                          $(hoverintent).removeClass("active");
                          $(hoverintent)
                            .children(".sub-menu")
                            .hide();
                        }
                      });
                  } else if (transition == "fade") {
                    $(hoverintent).removeClass("active");
                    $(hoverintent)
                      .find(".content")
                      .fadeOut(animation_time, function() {
                        if (hover == false) {
                          $(hoverintent)
                            .children(".sub-menu")
                            .hide();
                        }
                      });
                  } else {
                    $(hoverintent).removeClass("active");
                    $(hoverintent)
                      .children(".sub-menu")
                      .hide();
                    $(hoverintent)
                      .find(".content")
                      .hide();
                  }
                }
              }, 500);
            } else {
              if (transition == "slide") {
                $(this)
                  .find(".content")
                  .stop(true, true)
                  .animate({ height: "hide" }, animation_time, function() {
                    if (hover == false) {
                      $(active).removeClass("active");
                      $(active)
                        .children(".sub-menu")
                        .hide();
                    }
                  });
              } else if (transition == "fade") {
                $(active).removeClass("active");
                $(this)
                  .find(".content")
                  .fadeOut(animation_time, function() {
                    if (hover == false) {
                      $(active)
                        .children(".sub-menu")
                        .hide();
                    }
                  });
              } else {
                $(this).removeClass("active");
                $(this)
                  .children(".sub-menu")
                  .hide();
                $(this)
                  .find(".content")
                  .hide();
              }
            }
          }
        );
        this.menuloaded = true;
      }
    },
    setCartItems: async function() {
      let res = JSON.parse(localStorage.getItem("cart_product_ids"));
      if (res !== null) {
        this.cart_products = res.ids;
      }
      this.no_of_cart_items = this.cart_products.length;
      if (this.$store.state.buyer_accessToken === null) {
        this.no_of_cart_items = 0;
      }
    },
    setCartItemsStore: async function(obj) {
      this.cart_products = obj.ids;
      this.no_of_cart_items = obj.ids.length;
    },
    removeCartItems: async function(itemObj, index) {
      let cart_items = JSON.parse(localStorage.cart_product_ids);

      cart_items.ids.splice(index, 1);

      localStorage.removeItem("cart_product_ids");

      if (cart_items.ids.length === 0) {
        this.idObj.ids = [];
        localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
      } else {
        this.idObj.ids = [];
        cart_items.ids.map((obj) => {
          this.idObj.ids.push(obj);
          localStorage.setItem("cart_product_ids", JSON.stringify(this.idObj));
        });
      }

      this.no_of_cart_items = cart_items.ids.length;
      this.setCartItems();

      if (this.no_of_cart_items === 0 && this.$route.name === "Buyer Cart") {
        this.$router.push("/") ;
      }
    },
    handdleClickFlashDeals: function() {
      this.$router.push("/flashDeal");
    },
    pushToItem(item_id) {
      this.$router.push({ path: "product", query: { pid: item_id } });
    },
    haddleMadeInSrilanka() {
      this.$router.push("/madeinsrilankaproducts");
    },
    haddleETeleshop() {
      this.$router.push("/eTeleshop");
    }
  },
};
</script>
<style>
/* home page top header category set fixed height and position absolute according to body */
.container-megamenu.vertical .vertical-wrapper ul.megamenu > li {
  position: static;
}

#buyer-loggedin-header-middle {
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%);
}

#sub-menu-div {
  width: auto !important; 
  overflow: auto !important; 
  height: auto !important; 
  padding-top: 5px !important; 
  padding-bottom: 0px !important; 
  padding-left: 15px !important;
}

#madeSLLi img{
  height: 45px;
  margin-top: -10px;
}

.home-nav a{
  color: white !important;
}

.home-nav a:hover{
  color: #005baa !important;
}

.container-megamenu.vertical
  .vertical-wrapper
  ul.megamenu
  > li
  > .sub-menu
  .content {
  height: 500px;
  overflow-y: scroll;
}
/* ************************************************** */
.menu-profile {
  top: 109% !important;
  right: 10% !important;
  left: auto;
  color: #666;
  min-width: 200px !important;
}
.devider-profile {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}
.devider-profile1 {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}
.user-name {
  font-size: 12px;
  color: white !important;
  margin-right: 5px;
}

.typeheader-2 #sosearchpro .search button {
  /* background-color: #d02b10; */
}

.typeheader-2 #sosearchpro .search button:hover {
  /* background-color: #8c1401; */
}
/* Lanka Sathosa */

.typeheader-1 #sosearchpro .search input {
  border: none;
  /* border-top-left-radius: 4px; */
  /* border-bottom-left-radius: 4px; */
  /* border-bottom-right-radius: 4px; */
  /* border-top-right-radius: 4px; */
  z-index: 0;
  height: 40px;
  padding-left: 20px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #c8c8c8;
  /* background-color: #ebebeb; */
  color: #999;
  border-radius: 20px !important;
}
.typeheader-1 #sosearchpro .search button{
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px;
  top: 0 !important;
  right: 0 !important;
  height: 40px !important;
  line-height: 39px !important;
}

.typeheader-1 .header-middle {
  clear: both;
  margin: 0px 0 16px 0;
  /* padding-top: 20px; */
  /* background: url("/assets/image/bg/merchant-header@2x_bk.png") no-repeat center center; */
  background: #ffffff;
}

.typeheader-1
  .container-megamenu.vertical
  #menuHeading
  .megamenuToogle-pattern
  .container:after {
  font-family: "FontAwesome";
  content: "\f107";
  font-size: 16px;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 0;
  font-weight: 400;
  display: none;
}


.text-color-headder {
  /* color: #ffffff; */
}
.login-signup-text{
  color: #222222 !important;
}
.login-signup-text:hover{
  text-decoration: underline;
}

.balance-credit {
  text-align: right;
  background-color: white;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  line-height: 1;
}

.bottom1 {
  /* top: 12px; */
}

.bottom-row {
  /* min-height: 57px; */
}

.logo-bg12 {
  position: absolute;
  background-color: #f34b34;
  left: -20px;
  padding-left: 50px;
  top: -20px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 135px;
  /* border-bottom-right-radius: 90px; */
}

.logo-bg13 {
  position: absolute;
  background-color: #efefef;
  /* left: -20px; */
  /* padding-left: 50px; */
  top: -20px;
  /* padding-top: 5px; */
  /* padding-bottom: 10px; */
  /* padding-right: 80px; */
  /* border-bottom-right-radius: 90px; */
}
.logo-bg12 img {
  width: 67% !important;
}


#navImg {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 40px;
  vertical-align: top;
  text-align: center;
  overflow: hidden;
  background: white;
}

.logo-bg {
  position: absolute;
  background-color: #f34b34;
  left: -20px;
  padding-left: 50px;
  top: -20px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-right: 80px;
  /* border-bottom-right-radius: 90px; */
}
.logo-bg img {
  width: 67% !important;
}
.dropdown-menu > li > a:hover {
  color: white
}

.with-sub-menu:hover {
  background-color: #333 !important;
}

.cat-menusub:hover {
  color: #5779ae !important;
}
.cat-menusub .fa-angle-right:hover {
  color: #b82828 !important;
}

/* New header adjustment styles */
.newlogo-block {
  overflow: hidden;
  padding-left: 0px;
}
#buyerhdrlogoouter {
  width: 100%;
  height: 75px;
  margin: 0 0 0 -20px;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  background: #de1a22;
  overflow: hidden;
  position: relative;
}
#buyerhdrlogoouter img {
  display: block;
  width: 50%;
  margin: 10px auto 0 auto;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
}
.new-search-area-block {
  padding-top: 25px;
  text-align: center;
}
.new-search-area-block #sosearchpro .select_category {
  display: block;
}
.new-icon-area-block {
  padding-top: 25px;
}

.new-icon-area-block .new-wishlist-comp li {
  /* width: auto !important; */
  height: 35px !important;
  margin-left: 0px !important;
}
.new-icon-area-block .wishlist-comp li a.top-link-wishlist {
  width: auto !important;
  margin-left: 0px !important;
}

#buyerName:hover {
  color: #5779ae;
}
.cart-item-count{
  background: #5779ae!important;
  color: #ffffff !important;
  font-weight: 600;
}
.cat-list-tab{
  background: #5779ae !important;
  color: #ffffff !important;
  border-radius: 0px !important;
  margin-top: -1px !important;
}
.cat-list-icon{
  float: left;
  line-height: 44px;
  margin-right: 15px;
}
.cat-list-arrow{
  float: right;
  line-height: 44px;
}
.home-page-menu{
  width: 79%;
  margin-top: 10px;
}
.home-page-menu ul{
  list-style: none;
}
.home-page-menu ul li{
  list-style: none;
  display: inline-block;
  margin-right: 4%;
}
.home-page-menu ul li a{
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
}
.router-link-active{
  color:#005baa;
}
.typeheader-1 .header-top{
   /* background: #f2f2f2; */
}
.typeheader-1 .header-bottom{
  background: #0065e9;
}
#homelogosection {
  height: 163px;
  background-image: url('https://res.cloudinary.com/persystance-network/image/upload/v1608183515/cochchi/portal_user/1608183514902.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position-x: left !important;
  background-position-y: center !important;
  margin-bottom: -36px;
  margin-left: 14px;
}
#homelogosection img{
  max-width: 48%;
  margin-left: 12%;
  margin-top: 6%;
}
.typeheader-1 .searchdiv{
  width: 40%;
  padding-top: 40px;
  /* outline: 1px solid red; */
}
.typeheader-1 .bottom1{
  /* outline: 1px solid green; */
  width: 20%;
}
.typeheader-1 .cartbuttondiv{
  display: flex;
  flex-wrap: wrap;
  width: 37%;
  /* outline: 1px solid red; */
}
 .signin-w{
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  /* outline: 1px solid red; */
}
.typeheader-1 .cartbuttondiv .signin-w ul{
  width: 100%;
}
.typeheader-1 .cartbuttondiv .signin-w ul li{
  display: flex;
  flex-wrap: wrap;
  float: none;
  justify-content: flex-end;
}
#profile-dropdown-menu{
  min-width: 135px !important;
  max-width: 135px !important;
}
.typeheader-1 .signin-w ul li .signin-link-icon{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 30%;
  margin-right: -80px;
}
.typeheader-1 .signin-w ul li .signin-link-icon .fa{
  font-size: 36px;
  color: #000000;
}
.typeheader-1 .signin-w ul li .signin-link-text{
  flex-basis: 70%;
  text-align: right;
}
.typeheader-1 .signin-w ul li .signin-link-text .my-account{
  color: #949191;
  font-size: 13px;
}
.typeheader-1 .signin-w ul li .signin-link-text .login-register a{
  color: #000000;
  font-weight: 600;
  font-size: 13px;
}
.typeheader-1 .cartbuttondiv .shopping_cart{
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  /* outline: 1px solid red; */
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a.top_cart{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart-home{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: flex-end;
}
/* .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block{ */
  /* flex-basis: 50%; */
  /* outline: 1px solid red; */
/* } */
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .home-cart-count-block{
  /* flex-basis:  75% !important; */
  position: relative;
  /* outline: 1px solid red; */
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .fa{
  font-size: 36px;
  color: #000000;
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner{
  display: flex;
  justify-content: flex-end;
  margin-left: 0px;
  float: none;
  margin-right: -58px;
}
.typeheader-1 .shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .items_cart{
  /* left: 63px; */
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner .total-shopping-cart {
 
}
.typeheader-1 .buyer-loggedin-shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .items_cart{
  position: absolute;
  left: auto !important;
  top: 21px;
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li  .my-cart-btn-block{
  display: flex;
  flex-basis: 25%;
  padding-top: 14px;
  justify-content: flex-end;
  /* outline: 1px solid blue; */
}
.typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li  .my-cart-btn-block button{
  display: flex;
  align-content: flex-start;
  background: none !important;
  color: #000000;
  text-transform: none;
  font-size: 14px;
}
.typeheader-1 .wishlist-comp li.wishlist{
  margin-top: 0px;
}
.typeheader-1 .shopping_cart{
  margin-top: 0px;
}
.wishlist-comp-new{
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px !important;
}
.wishlist-comp-new li{
  display: flex;
  flex-basis: 100%;
}
.wishlist-comp-new li a{
  display: flex;
  flex-basis: 100%;
}
.wishlist-comp-new li a .icon-block{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 7% 7%;
  padding-right: 10px;
  /* outline: 1px solid red; */
}

.wishlist-comp-new li a .icon-block-login{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 7% 7%;
  padding-right: 10px;
  /* outline: 1px solid red; */
}

.wishlist-comp-new li a .icon-block-login img{
  width: fit-content !important;
  height: -webkit-fill-available !important;
  height: 100% !important;
}

.wishlist-comp-new li a .icon-block .fa{
  color: #000000 !important;
  font-size: 32px;
}
.wishlist-comp-new li a .icon-block .fa:hover{
    color: #5779ae !important;
}

.wishlist-comp-new li a .icon-block .fa:focus{
    color: #5779ae;
}

.wishlist-comp-new li a .login-info-block{
  flex-basis: 70%;
  /* outline: 1px solid green; */
}
.new-login{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
}
.new-login .btn-shopping-cart-new{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  order: 1;
}
.new-login .log-out-block{
  display: flex;
  flex-wrap: wrap;
  order: 2;
  flex-basis: 100%;
  padding-top: 16px!important;
  padding-left: 30%;
}
.wishlist-comp-new li a .login-info-block .mobile-user-icon{
  font-size: 12px;
  font-weight: bold;
  margin-right: 12px;
  display: none;
}
.mobile-user-icon-hp{
  display: none;
}
#hometabs{
  height: auto !important;
  padding-top: 0px !important;
}

/* New Link Block Styles */
.new-loggedin-link-block{
  display: flex;
  flex-wrap: wrap;
  /* background:#f0f0f0; */
  color: #000000;
}
.new-loggedin-link-block a{
  color: #000000;
}
.new-loggedin-link-block .link-block-left{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  /* outline: 1px solid blue; */
}
.new-loggedin-link-block .link-block-left ul{
  list-style: none;
}
.new-loggedin-link-block .link-block-left ul li{
  position: relative;
  border-top: none !important;
}
.new-loggedin-link-block .link-block-left ul li a{
  display: flex;
  flex-basis: 100%;
  text-align: center;
}
.new-loggedin-link-block .link-block-left ul li a img{
  display: block;
  width: 37px;
  height: 37px;
  border-radius: 40px;
}
.new-loggedin-link-block .link-block-left ul li ul{
  width: 145px !important;
  position: absolute;
  top: 37px;
  z-index: 1000000;
  background: #ffffff;
  border-radius: 4px;
  display: none;
}
.new-loggedin-link-block .link-block-left ul li ul li{
  min-height: 16px;
  border-bottom: 1px solid#f0f0f0;
}
.new-loggedin-link-block .link-block-left ul li ul li a{
  display: block;
  text-align: left;
  /* padding: 12px 12px; */
  line-height: 16px;
  color: #222222;
  font-size: 13px;
}
.new-loggedin-link-block .link-block-left ul li:hover > ul{
  display: block;
}
.new-loggedin-link-block .link-block-right{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 70%;
  padding-left: 2%;
  justify-content: flex-end;
  /* outline: 1px solid #000000; */
}
.new-loggedin-link-block .link-block-right .logged-buyer-name{
  display: flex;
  flex-basis: 100%;
  margin-top: 8px;
  justify-content: flex-end;
  /* outline: 1px solid red; */
}
.new-loggedin-link-block .link-block-right .logged-buyer-name a{
  color: #000000;
  text-align: right;
}
.new-loggedin-link-block .link-block-right .logoutbuyer-block{
  display: flex;
  flex-basis: 100%;
  margin-top: 9px;
  justify-content: flex-end;
  /* outline: 1px solid green; */
}
.new-loggedin-link-block .link-block-right .logoutbuyer-block a{
  color: #000000;
  font-weight: bold;
}

.buyer-common-newlink-list ul{
  text-align: right;
  display: block !important;
  text-align: right;
  width: 100%;
}
.buyer-common-newlink-list ul li ul{
  width: 190px;
  position: absolute;
  z-index: 1000000;
  background: #ffffff;
  border-radius: 4px;
  display: none !important;
-webkit-box-shadow: 0px 0px 4px 3px rgba(176,176,176,0.21);
-moz-box-shadow: 0px 0px 4px 3px rgba(176,176,176,0.21);
box-shadow: 0px 0px 4px 3px rgba(176,176,176,0.21);
}
.buyer-common-newlink-list ul li{
  position: relative;
  margin-left: 0px !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
  border: none !important;
  /* outline: 1px solid red; */
 
}
.buyer-common-newlink-list ul li:last-child{
  padding-right: 0px !important;
}
.buyer-common-newlink-list ul li:hover > ul{
  display: block !important;
}
.buyer-common-newlink-list ul li ul li{
  padding: 0px !important;
   margin-bottom: 1px;
  /* outline: 1px solid red; */
}
.buyer-common-newlink-list ul li ul li::after{
  content: "";
  width: 90%;
  height: 1px;
  display: block;
  margin: 0 auto;
  background: #f0f0f0;
}
.buyer-common-newlink-list ul li ul li:last-child::after{
  background: transparent;
}
.buyer-common-newlink-list ul li ul li a{
  display: block;
  padding: 12px 15px;
  text-align: left;
  /* padding: 12px 12px; */
  line-height: 16px;
  color: #222222;
  font-size: 13px;
  font-weight: bold;
  /* outline: 1px solid green; */
}
.buyer-common-newlink-list ul li ul li a .fa{
  margin-right: 15px;
}
.buyer-common-newlink-list ul li ul li a:hover{
  color: #005baa;
}
.typeheader-1 .buyer-common-newlink-list ul.top-link > li::after {
  display: none;
}

/* ASHEN CSS */
.nav-logo {
  width: 100%;
  background: #de1a22;
  overflow: hidden;
  position: relative;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.my-cart-btn {
  /* background: #ffd338 !important; */
  background: #000000 !important;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 6px;
  border: none;
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  line-height: 100%;
  border: none;
}
.my-cart-btn:hover{
  opacity: 0.7;
  color: #ffffff;
}

#hometabs {
  float: left;
  position: absolute;
  bottom: 0px;
}

#hometabsbuttons {
  float: left;
  position: relative;
  bottom: 0px;
}

.home-bedcrumb {
  font-size: 20px;
  color: #000000;
}
ul.breadcrumb li:not(:last-child):after {
  font-family: "FontAwesome";
  content: none !important;
  font-size: 13px;
  top: 0px;
  right: 0;
  position: absolute;
}
ul.breadcrumb li {
  display: inline-block;
  padding: 0 17px 0 8px;
  margin-right: 0px;
  position: relative;
}
ul.breadcrumb li a:hover {
  color:#5779ae;
}
ul.breadcrumb li:last-child a {
  color: #5779ae;
}
ul.breadcrumb {
  background-color: transparent;
  padding: 0;
  display: inline-block;
  width: 100%;
  line-height: 100%;
  padding-top: 0px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.newbuyerlgn-linkarea{
  padding-bottom: 7px;
  text-align: right;
}
.newbuyerlgn-linkarea ul{
  list-style: none;
}
.newbuyerlgn-linkarea ul li{
  list-style: none;
  display: inline-block;
  margin-left: 7px;
  margin-right: 7px;
}
.newbuyerlgn-linkarea ul li:last-child{
  margin-right: 0px;
}
.newbuyerlgn-linkarea ul li ul{
  width: 145px !important;
  position: absolute;
  top: 18px;
  z-index: 1000000;
  background: #ffffff;
  border-radius: 4px;
  display: none;
}
.newbuyerlgn-linkarea ul li ul li{
  margin-left: 0px;
  margin-right: 0px;
  display: block;
}
.newbuyerlgn-linkarea ul li ul li a{
  display: block;
  text-align: left;
}
.newbuyerlgn-linkarea ul li{
  position: relative;
}
.newbuyerlgn-linkarea ul li:hover > ul{
  display: block;
}




/* =================== Media queries =================== */
/* ==============================================*/

@media screen and (max-width: 2000px) {
    .breadcum-content-block{
        margin-left: 24%;
    }
    .new-loggedin-link-block .link-block-left ul li a img{
      margin: 0 auto;
    }
}
@media screen and (max-width: 1650px) {
  #homelogosection {
    height: 154px;
  }
}

@media screen and (max-width: 1600px) {
  #homelogosection {
    height: 149px;
  }
}

@media screen and (max-width: 1575px) {
    .breadcum-content-block{
        margin-left: 21%;
    }
    .typeheader-1 .signin-w ul li .signin-link-icon{
      margin-right: -65px;
    }
}

@media screen and (max-width: 1500px) {
  #homelogosection {
    height: 139px;
  }
}
@media screen and (max-width: 1440px) {
  #homelogosection {
      height: 133px;
  }
}

@media screen and (max-width: 1420px) {
    .typeheader-1 .signin-w ul li .signin-link-icon{
      margin-right: -48px;
    }
    .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner{
      margin-right: -32px;
    }
    .typeheader-1 .shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .items_cart{
      /* left: 32px; */
    }

}
@media screen and (max-width: 1390px) {
  .typeheader-1 .buyer-loggedin-shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .items_cart{
     left: auto !important;
  }


}





@media screen and (min-width: 1366px) {
  #buyerhdrlogoouter img {
    width: 50%;
  }
  .new-loggedin-link-block .link-block-right .logged-buyer-name a{
    font-size: 10px;
  }
}
@media screen and (max-width: 1280px) {
  #homelogosection {
    height: 117px;
  }
  .signin-w {
    margin-right: 1% !important;
  }
  .new-loggedin-link-block .link-block-left ul li a img{
    margin-left: -15px;
  }
  .buyer-common-newlink-list ul{
    width: 100%;
  }
  .buyer-common-newlink-list ul li{
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media screen and (max-width: 1250px) {
  .typeheader-1 .signin-w ul li .signin-link-icon{
    margin-right: -35px;
  }
  .new-loggedin-link-block .link-block-left ul li a img{
    margin-left: 0px;
  }

}      

@media screen and (max-width: 1220px) {
  .typeheader-1 .signin-w ul li .signin-link-icon{
    margin-right: -26px;
  }
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner{
    margin-right: -14px;
  }
  .typeheader-1 .shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .items_cart{
    /* left: 2px; */
  }
}

@media screen and (max-width: 1200px) {
  .home-page-menu{
    width: 75%;
  }
  .typeheader-1 .cartbuttondiv-logged-in{
    padding-top: 3% !important;
  }
  .typeheader-1 .searchdiv{
    padding-top: 5%;
  }
  .breadcum-content-block{
    margin-left: 25%;
  }
  /* .typeheader-1 .buyer-loggedin-header-middle{
    padding-top: 15px;
  } */
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner{
    margin-right: 0px;
  }
  .typeheader-1 .signin-w ul li .signin-link-icon{
    margin-right: 0px;
  }
  .typeheader-1 .homehdr-menu-vertical{
    width: 25% !important;
  }

  #homelogosection {
    height: 117px;
    /* height: 127px; */
    /* margin-bottom: -51px; */
  }
   
}

@media screen and (max-width: 1024px) {
  #homelogosection {
    height: 100px;
  }

  .home-header-bottom {
    padding-top: 3%;
  }
}

@media screen and (max-width: 1024px) {
  #homelogosection {
    height: 100px;
  }

  .home-header-bottom {
    padding-top: 3%;
  }
}

@media screen and (max-width: 992px) {
  #homelogosection {
    margin-left: 0px;
  }

  #sub-menu-div {
    width: 300px !important;
    background-color: rgb(167, 167, 157);
  }

  #homeHeaderSectionContainer {
    width: auto;
  }

  .newlogo-block {
    width: 40%;
  }
  #buyerhdrlogoouter img {
    width: 50%;
  }
  .home-page-menu{
   display: none;
  }
   /* .home-page-menu ul li a{
    color: #ffffff;
  } */
  .typeheader-2 .header-bottom{
      background: #5779ae !important;
  }
  /* .home-page-menu ul li{
    margin-right: 5%;
  }
  .home-page-menu ul li a{
    font-size: 13px;
  } */
  .typeheader-1 .bottom1{
    /* width: 30%; */
  }
  .typeheader-1 .searchdiv{
    width: 50%;
  }
  .typeheader-1 .cartbuttondiv{
    width: 30%;
  }
  .typeheader-1 .searchdiv{
    /* width: 60%; */
  }
  .new-loggedin-link-block .link-block-left{
    margin-right: -50px;
  }
  .new-loggedin-link-block{
    padding-top: 15px;
  }
  .buyer-loggedin-shopping_cart{
    padding-top: 15px;
  }
  /* .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .my-cart-btn-block{ */
    /* justify-content: flex-start; */
  /* } */
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .my-cart-btn-block{
    justify-content: flex-start;
  }
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner{
    margin-right: 0px;
  }
  .buyer-common-newlink-list{
      padding-right: 10px;
  }
  .typeheader-1 .homehdr-menu-vertical{
    width: 100% !important;
  }
}

@media (max-width: 900px) {
  .view-cart {
    width: 96px !important;
    max-width: 100px !important;
  }
  .cart-dropdown {
    width: 450px !important;
    max-width: 420px !important;
    min-width: 250px !important;
  }
  .table-cart {
    width: 450px !important;
    max-width: 416px !important;
    min-width: 250px !important;
  }
}

@media (max-width: 850px) {
  #homelogosection {
    height: 88px;
  }

  #homelogosection img {
    max-width: 60%;
  }
}


@media screen and (max-width: 768px) {
  .shopping_cart {
    margin-right: 1.25%;
  }

  header.typeheader-1 .search-header-w {
    width: 100%;
  }
  .typeheader-1 .bottom1{
    width: 100%;
  }
  .typeheader-1 .cartbuttondiv{
    width: 35%;
    margin-bottom: 20px;
  }
  .typeheader-1 .searchdiv{
    /* width: 100%; */
    width: 65%;
  }
   .typeheader-1  .cartbuttondiv-logged-in{
     margin-top: -20px !important;
   }
  .typeheader-1 .signin-w ul li .signin-link-icon {
    margin-right: -115px;
  }
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li{
    /* padding-top: 20px; */
  }
  .typeheader-1 .cartbuttondiv .signin-w{
    padding-top: 20px !important;
  }
  .new-loggedin-link-block .link-block-left{
    margin-right: -140px;
  }
  .typeheader-1 .header-top .header-top-right-common{
    padding-right: 6%;
  }
}

@media screen and (max-width: 768px) {
  .shopping_cart {
    margin-right: 1.25%;
  }

  header.typeheader-1 .search-header-w {
    width: 100%;
  }
  #homelogosection {
    height: 124px;
    background-size: auto !important;
  }
  #homelogosection img{
    max-width: 16%;
    margin-top: 1%;
    margin-left: 6%;
  }

  .home-header-bottom {
    padding-top: 1%;
  }
  .typeheader-1 .bottom1{
    width: 100%;
  }
  .typeheader-1 .cartbuttondiv{
    /* width: 100%; */
    margin-bottom: 20px;
  }
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner .total-shopping-cart{
    left: 55px;
  }
  .typeheader-1 .signin-w ul li .signin-link-icon {
    margin-right: -115px;
  }
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li{
    /* padding-top: 20px; */
  }
  .typeheader-1 .cartbuttondiv .signin-w{
    padding-top: 20px !important;
  }
  .new-loggedin-link-block .link-block-left{
    margin-right: -140px;
  }
}

@media screen and (max-width: 725px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: -115px;
  }
}


@media screen and (max-width: 700px) {
  .typeheader-1 .signin-w ul li .signin-link-icon {
      margin-right: -95px;
  }
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner{
    margin-right: 0px;
  }
  .typeheader-1 .shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .items_cart{
    /* left: 0px; */
  }

}
@media screen and (max-width: 675px) {
  .typeheader-1 .searchdiv {
    padding-top: 8%;
  }

  .typeheader-1 .cartbuttondiv-logged-in {
    padding-top: 0% !important;
  }

  #homelogosection img {
    max-width: 19%;
    margin-top: 1%;
    margin-left: 6%;
  }
}
@media screen and (max-width: 575px) {
  /* #commn-search-col {
    display: block;
    min-height: 50px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 14px;
  } */
  #homelogosection img {
    max-width: 25%;
    margin-top: 1%;
    margin-left: 6%;
  }

  #common-search-input {
    display: block !important;
  }
  #buyerhdrlogoouter {
    margin-left: 0px;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  }
  #buyerhdrlogoouter img {
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
  }
  #buyerhdrlogoouter img {
    width: 20%;
  }
  .typeheader-1 .header-top .header-top-right-common {
    padding-right: 5%;
  }
}


@media screen and (max-width: 475px) {
  .shopping_cart {
    margin-right: 1.75%;
  }
  .typeheader-1 .new-wishlist-comp {
    margin-right: 1% !important;
  }
  .newlogo-block {
    width: 35%;
  }
  #buyerhdrlogoouter img {
    width: 25%;
  }
  .new-icon-area-block {
    font-size: 9px !important;
  }
  .new-icon-area-block .fa-shopping-cart {
    font-size: 18px !important;
  }
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner .total-shopping-cart{
    left: 30%;
  }
  .new-login .btn-shopping-cart-new{
    flex-basis: 100%;
  }
   .new-login .log-out-block{
    flex-basis: 100%;
    margin-top: 40px;
    padding-left: 20px;
  }
  .new-loggedin-link-block .link-block-right .logged-buyer-name{
    font-size: 11px;
  }
}


@media screen and (max-width: 675px) {
  #buyerhdrlogoouter {
    height: 65px;
  }
  .newlogo-block {
    width: 50%;
  }
  .new-search-area-block {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .new-icon-area-block {
    padding-top: 15px;
  }
  .typeheader-1 .searchdiv{
    /* width: 100%; */
  }
  .typeheader-1 .signin-w ul li .signin-link-icon{
    display: none;
  }
  .typeheader-1 .signin-w ul li .signin-link-text{
    padding-top: 15px;
    flex-basis: 100%;
  }
  .typeheader-1 .signin-w ul li .signin-link-text .my-account{
    display: none;
  }
  .wishlist-comp-new li a .icon-block{
    display: none;
  }
  .new-login .log-out-block{
    padding-left: 0px;
    padding-top: 0px !important;
  }
   .wishlist-comp-new li a .login-info-block{
     flex-basis: 100%;
     margin-top: 20px;
   }
  .wishlist-comp-new li a .login-info-block .my-acc{
    display: none;
  }
  .new-login .btn-shopping-cart-new{
    flex-basis: 65%;
  }
   .new-login .log-out-block{
    flex-basis: 35%;
    margin-top: 20px;
  }
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner .total-shopping-cart{
    left: 42%;
  }
  .wishlist-comp-new li a .login-info-block .mobile-user-icon{
    display: inline-block;
  }
  .mobile-user-icon-hp{
    display: inline-block;
    font-weight: bold;
  }
  .new-loggedin-link-block .link-block-right .logged-buyer-name a{
    font-size: 10px;
  }
  .typeheader-1 .shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .buyer-loggedin-cart-item-count{
    left: 0px;
  }
}

@media screen and (max-width: 625px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: -100px;
  }

}

@media screen and (max-width: 575px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: -80px;
  }

}

@media screen and (max-width: 525px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: -55px;
  }
}
@media screen and (max-width: 475px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: -35px;
  }
  .typeheader-1 .shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .buyer-loggedin-cart-item-count{
    left: -8px;
  }

  #homelogosection{
    background-color: #fff;
    text-align: center;
    height: 105px;
  }

  #homelogosection img {
    max-width: 25%;
    margin-top: 1%;
    margin-left: 0%;
  }

  .home-header-bottom {
    padding-top: 2px;
  }

  .typeheader-1 .buyer-prof-searchdiv {
      padding-top: 10% !important;
  }

  .typeheader-1 .buyer-prof-cartbuttondiv {
    padding-top: 42px !important;
  }

}

@media screen and (max-width: 425px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: -20px;
  }

  .typeheader-1 .searchdiv {
    padding-top: 10%;
  }

  #homelogosection img {
    max-width: 25%;
    margin-top: 2px;
    margin-left: 0%;
  }

  #homelogosection{
    background-color: #fff;
    text-align: center;
    height: 92px;
  }

}

@media (max-width: 375px) {
  #homelogosection img {
    max-width: 25%;
    margin-top: 2px;
    margin-left: 0%;
  }

  #homelogosection{
    background-color: #fff;
    text-align: center;
    height: 83px;
  }

  .home-header-bottom {
    padding-top: 2px;
  }


  .typeheader-1 .searchdiv {
    padding-top: 11%;
  }

  .typeheader-1 .cartbuttondiv-logged-in {
    padding-top: 1% !important;
  }

  .view-cart {
    width: 96px !important;
    max-width: 100px;
  }

  .cart-dropdown {
    max-width: 310px !important;
    min-width: 250px !important;
  }
  .table-cart {
    width: 450px !important;
    max-width: 306px !important;
    min-width: 250px !important;
  }
  .new-loggedin-link-block .link-block-left{
    margin-right: 0px;
  }
  .typeheader-1 .shopping_cart .btn-shopping-cart .top_cart .shopcart-inner .total-shopping-cart .buyer-loggedin-cart-item-count{
    left: -16px;
  }
  .newlogo-block {
    width: 100%;
    padding-right: 0px;
  }
  .new-icon-area-block .typeheader-1 .wishlist-comp {
    float: left !important;
  }
  .new-icon-area-block .typeheader-1 .typeheader-1 .signin-w {
    float: right !important;
  }

  #buyerhdrlogoouter img {
    width: 35%;
  }
  .typeheader-1 .cartbuttondiv{
    width: 40%;
  }
  .typeheader-1 .searchdiv{
    width: 60%;
  }
}

@media screen and (max-width: 320px) {
    .typeheader-1 .searchdiv{
      width: 100%;
      padding-top: 12%;
    }
    .typeheader-1 .cartbuttondiv{
      width: 100%;
    }

    .buyer-loggedin-shopping_cart {
        padding-top: 3px;
    }

}



/* ============================================ */

/* ============================================ */
@media only screen and (min-width: 768px) {
  #hometabs {
    padding-top: 20px;
    position: unset;
  }
  #hometabsbuttons {
    float: left;
    position: relative;
    bottom: auto;
  }
  #buyerName {
    font-size: inherit;
  }
  .user-name {
    font-size: inherit;
    color: white !important;
    margin-right: 5px;
  }
  .content {
    margin-top: auto;
  }
  .content {
    margin-top: auto;
  }

}

@media only screen and (min-width: 992px) {
  #hometabs {
    position: relative;
  }
  #hometabsbuttons {
    float: left;
    position: relative;
    bottom: auto;
  }
  #hometabsbuttons button {
    min-width: 120px;
  }
  #buyerName {
    font-size: inherit;
  }
  .user-name {
    font-size: inherit;
    color: white !important;
    margin-right: 5px;
  }
  .content {
    margin-top: -40px;
  }
}

@media screen and (min-width: 1075px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: 0px;
  }
}

@media screen and (min-width: 1175px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1200px) {
  #hometabs {
    padding-top: 20px;
    position: relative;
    height: 60px;
  }
  #hometabsbuttons {
    float: left;
    position: absolute;
    bottom: 0px;
  }
  #hometabsbuttons button {
    min-width: 120px;
  }

  #buyerName {
    font-size: inherit;
  }
  .user-name {
    font-size: inherit;
    color: white !important;
    margin-right: 5px;
  }
  .content {
    margin-top: -40px;
  }
}

@media screen and (min-width: 1275px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: -50px;
  }
}

@media screen and (min-width: 1475px) {
  #buyerhdrlogoouter img {
    width: 40%;
  }
  .new-loggedin-link-block .link-block-left{
    margin-right: -80px;
  }
}

@media screen and (min-width: 1575px) {
  .typeheader-1 .cartbuttondiv .shopping_cart .btn-shopping-cart a .shopcart ul li .cart-count-block .shopcart-inner .total-shopping-cart{
    /* left: 38px; */
  }
}
@media screen and (min-width: 1675px) {
  .new-loggedin-link-block .link-block-left{
    margin-right: -95px;
  }
  #buyerhdrlogoouter img {
    width: 47.5%;
  }
}

@media screen and (min-width: 1980px) {
  #buyerhdrlogoouter img {
    width: 35%;
  }
}

@media screen and (min-width: 2000px) {
  #buyerhdrlogoouter img {
    width: 55%;
  }
}


</style>