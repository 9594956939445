<template>
<div class="product-layout item-inner style1 ">
    <div class="item-image">
        <div class="item-img-info">
            <a href="product.html" target="_self" title="Mandouille short "><img src="assets/image/catalog/demo/product/80/5.jpg" alt="Mandouille short"></a>
        </div>                                        
    </div>
    <div class="item-info">
        <div class="item-title">
            <a href="product.html" target="_self" title="Mandouille short">Mandouille short </a>
        </div>
        <div class="rating">
            <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
            <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
            <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
            <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
            <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
        </div>
        <div class="content_price price">
            <span class="price-new product-price">$55.00 </span>&nbsp;&nbsp;
            <span class="price-old">$76.00 </span>&nbsp;
        </div>
    </div>
</div>
</template>
<script>
export default {
name: 'ProductTileMini',
}
</script>

