<template>
  <div>
    <div class="module category-style" style="border: 0px solid #5779ae; margin-top: 5px;">
      <h3 class="modtitle" style="background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%); color: #ffffff;">
        <i class="fa fa-list falist-side" style="color: #F26F21"></i>
        {{data.title}}
      </h3>
      <div class="modcontent" style="background: #BFDEFE; height: 638px;">
        <div class="box-category">
          <ul
            id="cat_accordion"
            class="list-group"
            style="display: block; font-size: 14px; font-weight: 400; padding: 8px 0; line-height: 22px; position: relative; text-transform: capitalize; background: none; height: auto;"
          >
            <li
              v-bind:class="chlidClass(item)"
              v-for="(item, index) in data.linksArray"
              :key="index"
            >
              <a
                @click.prevent.stop="pushTo(item.path)"
                class="cutom-parent"
                :class="{ 'active': item.active }"
              >{{item.name}}</a>
              <span v-if="item.child" class="button-view fa fa-plus-square-o"></span>
              <ul
                style="display: block; margin-bottom: 0px;"
                v-for="(itemsub, indexsub) in item.child"
                :key="indexsub"
              >
                <li>
                  <a @click.prevent.stop="pushTo(itemsub.path)">{{itemsub.name}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object
    }
  },
  data: function() {
    return {
      features: [
        {
          image: "assets/image/catalog/banners/cat1.jpg",
          title: "Great Value",
          description: "Lorem Ipsum is simply dummy text"
        },
        {
          image: "assets/image/catalog/banners/cat1.jpg",
          title: "Secure Payment",
          description: "Lorem Ipsum is simply dummy text"
        },
        {
          image: "assets/image/catalog/banners/cat1.jpg",
          title: "24 x 7 Help",
          description: "Lorem Ipsum is simply dummy text"
        },
        {
          image: "assets/image/catalog/banners/cat1.jpg",
          title: "Island Wide Delivery",
          description: "Lorem Ipsum is simply dummy text"
        },
        {
          image: "assets/image/catalog/banners/cat1.jpg",
          title: "Open Store",
          description: "Lorem Ipsum is simply dummy text"
        },
        {
          image: "assets/image/catalog/banners/cat1.jpg",
          title: "Stay Touched",
          description: "Lorem Ipsum is simply dummy text"
        }
      ]
    };
  },
  beforemounted() {
    $(document).ready(function() {
      $("#cat_accordion").cutomAccordion({
        eventType: "click",
        autoClose: true,
        saveState: true,
        disableLink: true,
        speed: "slow",
        showCount: false,
        autoExpand: true,
        cookie: "dcjq-accordion-1",
        classExpand: "button-view"
      });
    });
  },
  methods: {
    pushTo(pathlink) {
      this.$router.push(pathlink);
    },
    chlidClass(obj) {
      if (obj.child) {
        return "hadchild";
      } else {
        return "";
      }
    }
  }
};
</script>
<style>
.static-cates ul li {
  padding: 0 15px;
  float: left;
  /* width: 16.66%; */
}

.static-cates ul li h4 {
  margin-top: 15px;
}
.falist-side {
  margin-right: 15px;
  font-size: 14px;
}
#cat_accordion li a:hover {
  color: #5779ae;
}
#cat_accordion li a.active {
  color: #5779ae;
}
</style>
