<template>
<div>
    <!-- <MyAccountLeftCategories v-bind:title="menutitle" v-bind:menulist="this.linksArray" />  -->
 
<component  :is="items.type" v-bind:data="items.data" v-for="items in leftnavCompoArray" :key="items.type"></component>
 
     <!-- <div class="module banner-left hidden-xs ">
                	<div class="banner-sidebar banners">
                      <div>
                        <a title="Banner Image" href="#"> 
                          <img src="assets/image/catalog/banners/banner-sidebar.jpg" alt="Banner Image"> 
                        </a>
                      </div>
                    </div>
                </div>    -->
</div>
</template>
<script>
import ProductTile from '@/components/product/product_tile.vue'
import ProductTileMini from '@/components/product/product_tile_mini.vue'
import sidebarLinksBuyer from '../../sidebarLinksBuyer'

// COMMON COMPONENTS
import MyAccountLeftCategories from '@/components/common/basic_left_menu.vue'

// LEFT ASID COMPONENTS - BUYER
import AdvertSingleImage from '@/components/buyer/leftnav/AdvertSingleImage.vue'
import ProductFilters from '@/components/buyer/leftnav/ProductFilters.vue'

export default {
  name: 'LeftNavBar',
    components: {
        sidebarLinksBuyer,
        MyAccountLeftCategories,
        ProductFilters,
        AdvertSingleImage,
    },
    data: function () {
        return {
        menutitle: "CATEGORIES",
        userRole: 1,
        breadcrumb: [
            {
            text: "Dashboard",
            href: "#",
            active: true
            }
        ],
        linksArray:[],
        leftnavCompoArray: []
        }
    },
    watch:{
        $route (to, from){            
            this.getPageLeft(to.name);
            this.init();
        }
    }, 
    created() {
        this.linksArray = sidebarLinksBuyer;  
        this.getPageLeft(this.$route.name);    
        this.init();
    },
     computed: {
        widgetView() {
            return "MyAccountLeftCategories" 
        }
    },
    methods: {
        init() {
            if(!localStorage.buyer_data){
              return;
            }
          let user = JSON.parse(localStorage.getItem("buyer_data"));
          this.userRole = user.user_role != undefined ? user.user_role : "";
          if(this.userRole != 2){
            return;
          }
          if(this.leftnavCompoArray[0]=== undefined){
            return;
          }
          if(this.leftnavCompoArray[0].data === undefined){
            return;
          }else{
              if(this.leftnavCompoArray[0].data.linksArray[0].name == "Profile"){
                  this.leftnavCompoArray[0].data.linksArray.shift();
              }
          }
        },
        getPageLeft(currentpage) {
           
          let data =  this.$store.state.globalcomponentdata.filter(g => g.page == currentpage)

          if(data.length > 0){
              data[0].leftnav.forEach(element => {
                  
                  if(element.type === "MyAccountLeftCategories" ){
                      element.data.linksArray.forEach(link => {
                            if(link.subpaths){
                                if(link.subpaths.includes(this.$route.name) || link.path === this.$route.path ){
                                link.active = true;
                                } else {
                                link.active = false;
                                }
                            } else {
                                if(link.path === this.$route.path ){
                                link.active = true;
                                } else {
                                link.active = false;
                                }
                            }
                        });
                  }
                    
                });
              this.leftnavCompoArray = data[0].leftnav;
               
          } else {
              this.leftnavCompoArray = [];
          } 
        }
    }
}
</script>
<style>
.mt-17{
margin-top:17px !important;
}

.mt-25{
margin-top:25px !important;
}
</style>
