<template>
  <div class="content-top-w">
    <!-- <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12 main-left">
      <div class="module col1 hidden-sm hidden-xs"></div>
    </div> -->
    <div  style="background-color: white; margin-top: 2.5%; margin-bottom: 2.5%; padding: 10px;">
      <div class="row" style="display: flex; flex-wrap: wrap; margin-right: 0px; margin-left: 0px;">

        <div
          id="homebannersection"
          class="col-table col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12"
        >
          <div class="row">
            <!-- NEEDED SIZE 1200x525 - ASHEN -->
            <div class="col-md-12">
              <div class="box-content1">
                <div class="module sohomepage-slider slider-size">
                  <div id="demo" class="slider-size">
                    <div id="test" class="owl-carousel slider-size"></div>
                  </div>

                  <div
                    class="yt-content-slider"
                    data-rtl="yes"
                    data-autoplay="no"
                    data-autoheight="no"
                    data-delay="4"
                    data-speed="0.6"
                    data-margin="0"
                    data-items_column00="1"
                    data-items_column0="1"
                    data-items_column1="1"
                    data-items_column2="1"
                    data-items_column3="1"
                    data-items_column4="1"
                    data-arrows="no"
                    data-pagination="yes"
                    data-lazyload="yes"
                    data-loop="no"
                    data-hoverpause="yes"
                  ></div>

                  <div class="loadeding"></div>
                </div>
                <!-- Test banner starts -->
                <!-- <div ref="bannerModule">
                </div> -->
                <!-- Test banner ends -->

              </div>
            </div>
          </div>
        </div>

        <div
          class=" col-lg-3 col-md-12 col-sm-12 col-xs-12"
          style="
            background-color: white;
            padding: 10px 5px 4px;
            display: flex;
            border-radius: 5px;
          "
        >

          <div class="text-center" id="welcomeSection" v-if="!this.$store.state.buyer_accessToken">
            <div class="welcomeSection-inner" >
            <h2 id="welcometxt">WELCOME TO COCHCHI.LK</h2>
            <button
              @click="regNew()"
              class="btn btn-primary w-100"
            >
              Join Now
            </button>
            <p style="color: white;font-size: 18px" class="mx-auto">or</p>
            <button
              @click="loginNew()"
              class="btn btn-secondary w-100"
            >
              Sign In
            </button>
            </div>
          </div>

          <div class=" text-center" id="welcomeSection" v-if="this.$store.state.buyer_accessToken">
            <div class="welcomeSection-inner" >
            <img :src="this.$store.state.whiteLogo" alt="mdb logo" style="border-radius: 10px; width: 50%;">
            <h2 id="welcometxt">WELCOME TO COCHCHI.LK</h2>
            </div>
          </div>
          
          <!-- <div
            v-for="(item, index) in pramoRightBottomBanners"
            :key="index"
            id="welcomeUnderSection"
            class="col-sm-6 col-lg-12"
          >
            <a :href="item.url" target="_blank"
              ><img
                style="padding: 5px; box-sizing: border-box; height: 100%"
                :src="item.image"
                alt="image"
            /></a>
          </div> -->
          <!-- Test banner starts -->
                <!-- Test banner starts -->
                <div ref="bannerModule">
                </div>
                <!-- Test banner ends -->
                <!-- Test banner ends -->
        </div>

      </div>
      <div class="row" id="mainBannerUnderSection">
        <div class="col-md-12">
          <div
            v-for="(item, index) in pramoBanners"
            :key="index"
            style="padding: 0px; margin: 0px"
          >
            <a :href="item.url" target="_blank"
              ><img
                class="col-lg-2 col-md-4 col-sm-4 col-xs-4"
                style="padding: 5px; box-sizing: border-box"
                :src="item.image"
                alt="image"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <BuyerLoginModal id="loginModal" ref="tabActive" />
  </div>
</template>
<script>
import mixinAdvertisments from "@/mixins/APICommon";
import BuyerLoginModal from "@/views/Buyer/BuyerLogin/BuyerLloginModal.vue";

export default {
  name: "HomePageBanner",
  components: { BuyerLoginModal },
  mixins: [mixinAdvertisments],
  data: function () {
    return {
      sliderbanners: [],
      rightbanners: [],
      pramoBanners: [
        // {
        //   image:
        //     "https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/cat1.jpg",
        //   url: "",
        // },
        // {
        //   image:
        //     "https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/cat2.jpg",
        //   url: "",
        // },
        // {
        //   image:
        //     "https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/cat3.jpg",
        //   url: "",
        // },
        // {
        //   image:
        //     "https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/cat4.jpg",
        //   url: "",
        // },

        // {
        //   image:
        //     "https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/cat3.jpg",
        //   url: "",
        // },
        // {
        //   image:
        //     "https://demo.smartaddons.com/templates/html/supermarket/image/catalog/banners/cat4.jpg",
        //   url: "",
        // },

      ],
      pramoRightTopBanners: [
        // {
        //   image:
        //     "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1601376464/slt/advertisement/2/1601376464054.jpg",
        //   url: "",
        // },
      ],
      pramoRightBottomBanners: [
        // {
        //   image:
        //     "https://res.cloudinary.com/persystance-network/image/upload/q_auto,f_auto/v1601376464/slt/advertisement/2/1601376464054.jpg",
        //   url: "",
        // },
      ],
    };
  },
  // beforemounted() {
  //   $(document).ready(function () {
  //     // $(".owl2-carousel").owlCarousel({

  //     //     responsiveClass: true,
  //     //                     mouseDrag: true,
  //     //                     video:true,
  //     //                     lazyLoad: (data.lazyload == 'yes') ? true : false,
  //     //                     autoplay: (data.autoplay == 'yes') ? true : false,
  //     //                     autoHeight: (data.autoheight == 'yes') ? true : false,
  //     //                     autoplayTimeout: data.delay * 1000,
  //     //                     smartSpeed: data.speed * 1000,
  //     //                     autoplayHoverPause: (data.hoverpause == 'yes') ? true : false,
  //     //                     center: (data.center == 'yes') ? true : false,
  //     //                     loop: (data.loop == 'yes') ? true : false,
  //     //                     dots: (data.pagination == 'yes') ? true : false,
  //     //                     nav: (data.arrows == 'yes') ? true : false,
  //     //                     dotClass: "owl2-dot",
  //     //                     dotsClass: "owl2-dots",
  //     //                     margin: data.margin,
  //     //                     navText: ['',''],
  //     //                     responsive: {
  //     //                         0: {items: data.items_column4},
  //     //                         480: {items: data.items_column3},
  //     //                         768: {items: data.items_column2},
  //     //                         992: {items: data.items_column1},
  //     //                         1200: {items: data.items_column0},
  //     //                         1650: {items: data.items_column00}
  //     //                     }

  //     // });

  //     $(".yt-content-slider").each(function () {
  //       var $slider = $(this),
  //         $panels = $slider.children("div"),
  //         data = $slider.data();
  //       // Remove unwanted br's
  //       //$slider.children(':not(.yt-content-slide)').remove();
  //       // Apply Owl Carousel

  //       // $slider.owlCarousel2({
  //       //     responsiveClass: true,
  //       //     mouseDrag: true,
  //       //     video:true,
  //       //     lazyLoad: (data.lazyload == 'yes') ? true : false,
  //       //     autoplay: (data.autoplay == 'yes') ? true : false,
  //       //     autoHeight: (data.autoheight == 'yes') ? true : false,
  //       //     autoplayTimeout: data.delay * 1000,
  //       //     smartSpeed: data.speed * 1000,
  //       //     autoplayHoverPause: (data.hoverpause == 'yes') ? true : false,
  //       //     center: (data.center == 'yes') ? true : false,
  //       //     loop: (data.loop == 'yes') ? true : false,
  //       //     dots: (data.pagination == 'yes') ? true : false,
  //       //     nav: (data.arrows == 'yes') ? true : false,
  //       //     dotClass: "owl2-dot",
  //       //     dotsClass: "owl2-dots",
  //       //     margin: data.margin,
  //       //     navText: ['',''],
  //       //     responsive: {
  //       //         0: {items: data.items_column4},
  //       //         480: {items: data.items_column3},
  //       //         768: {items: data.items_column2},
  //       //         992: {items: data.items_column1},
  //       //         1200: {items: data.items_column0},
  //       //         1650: {items: data.items_column00}
  //       //     }
  //       // });
  //     });

  //     // var hsliderHeight = document.getElementById("hslider").offsetHeight;
  //     // alert(hsliderHeight);
  //   });
  //   this.getAddHereBanner();
  // },
  watch: {
    sliderbanners: function (newVal, oldVal) {
      let self = this;
      // var myVar = setInterval(self.sliderInit() , 1000);

      // $('.yt-content-slider').trigger('refresh.owl.carousel');
    }
  },
  created() {
    this.getBanners();
    // this.loginNew();
    // this.sliderInit();
  },
  methods: {
    loginNew: async function () {
      if (this.$store.state.buyer_accessToken === null) {
        // this.$refs.tabActive.activetab = 1;
        $("#BuyerLoginModal").modal("show");
        if ( document.getElementById("tabView") !== null ) {
          document.getElementById("tabView").childNodes[0].childNodes[0].click()
        }
        if (this.$refs.tabActive.showforgotpass === true) {
          // hide forget password modal when login
          this.$refs.tabActive.showforgotpass = false;
        }
      }
    },
    regNew: async function () {
      if (this.$store.state.buyer_accessToken === null) {
        // console.log(this.$refs.tabActive)
        // this.$refs.tabActive.activetab = 2;
        $("#BuyerLoginModal").modal("show");
        if ( document.getElementById("tabView") !== null ) {
          document.getElementById("tabView").childNodes[0].childNodes[1].click()
        }
        if (this.$refs.tabActive.showforgotpass === true) {
          // hide forget password modal when login
          this.$refs.tabActive.showforgotpass = false;
        }
      }
    },
    getBanners: async function () {
      try {
        let response = await this.getAdvertisments();
        var bannerdata = response.filter(function (res) {
          return res.image_type === 1;
        });

        this.sliderbanners = bannerdata;

        let banners = "";
        this.sliderbanners.forEach((element) => {
          banners +=
            '<div class="yt-content-slide"><a href="' +
            element.url +
            '" target="_blank"><div class="item"><img src="' +
            element.image +
            '" class="img-responsive" style="border-radius: 5px;" /> </div></a> </div>';
        });

        $(".yt-content-slider").owlCarousel2({});

        // data-rtl="yes" data-autoplay="no"
        // data-autoheight="no"
        // data-delay="4"
        // data-speed="0.6"
        // data-margin="0"
        // data-items_column00="1" data-items_column0="1" data-items_column1="1" data-items_column2="1"
        // data-items_column3="1" data-items_column4="1" data-arrows="no" data-pagination="yes" data-lazyload="yes" data-loop="no" data-hoverpause="yes">

        $("#demo").html('<div id="testing" class="owl-carousel"></div>');
        this.sliderbanners.forEach((element) => {
          $(".owl-carousel").append(
            '<div class="item yt-content-slide"><img src="' +
              element.image +
              '" style="border-radius: 5px; width:1650px" /></div>'
          );
        });

        // $(".owl-carousel").append('<div class="item yt-content-slide"><img src="https://res.cloudinary.com/persystance-network/image/upload/v1586064754/slt/portal_user/1586064753849.jpg" /></div>');
        var owl = $("#testing");
        owl.owlCarousel2({
          responsiveClass: true,
          mouseDrag: true,
          video: true,
          lazyLoad: true,
          autoplay: true,
          autoHeight: false,
          autoplayTimeout: 4 * 900,
          slideSpeed: 700,
          paginationSpeed: 600,
          smartSpeed: 300,
          autoplayHoverPause: true,
          center: true,
          loop: true,
          dots: true,
          nav: false,
          dotClass: "owl2-dot",
          dotsClass: "owl2-dots",
          margin: 0,
          navText: ["", ""],
          responsive: {
            0: { items: 1 },
            480: { items: 1 },
            768: { items: 1 },
            992: { items: 1 },
            1200: { items: 1 },
            1650: { items: 1 },
            1920: { items: 1 },
          },
        });

        var rightbannerdata = response.filter(function (res) {
          return res.image_type === 2;
        });
        // document.getElementById("welcomeSection").style.backgroundImage = url('')
        document.getElementById("welcomeSection").style.backgroundImage = "url(" + rightbannerdata[0].image + ")";


        this.rightbanners = rightbannerdata;

        let promo = response.filter(function (res) {
          return res.image_type === 7;
        });

        // let backgroundRight = response.filter(function (res) {
        //   return res.image_type === ;
        // });

        this.pramoBanners = promo;
        
      } catch (error) {
        console.log(error);
      }
    },
    sliderInit() {
      // $('.yt-content-slider').each(function () {
      //     var $slider = $(this),
      //         $panels = $slider.children('div'),
      //         data = $slider.data();
      //         // $slider.append(banners);
      //         var $owl = $slider.owlCarousel2({
      //             responsiveClass: true,
      //             mouseDrag: true,
      //             video:true,
      //             lazyLoad: (data.lazyload == 'yes') ? true : false,
      //             autoplay: (data.autoplay == 'yes') ? true : false,
      //             autoHeight: (data.autoheight == 'yes') ? true : false,
      //             autoplayTimeout: data.delay * 1000,
      //             smartSpeed: data.speed * 1000,
      //             autoplayHoverPause: (data.hoverpause == 'yes') ? true : false,
      //             center: (data.center == 'yes') ? true : false,
      //             loop: (data.loop == 'yes') ? true : false,
      //             dots: (data.pagination == 'yes') ? true : false,
      //             nav: (data.arrows == 'yes') ? true : false,
      //             dotClass: "owl2-dot",
      //             dotsClass: "owl2-dots",
      //             margin: data.margin,
      //             navText: ['',''],
      //             responsive: {
      //                 0: {items: data.items_column4},
      //                 480: {items: data.items_column3},
      //                 768: {items: data.items_column2},
      //                 992: {items: data.items_column1},
      //                 1200: {items: data.items_column0},
      //                 1650: {items: data.items_column00}
      //             }
      //         });
      // });
    },
    getAddHereBanner() {
        this.$refs.bannerModule.innerHTML = `
                    <!--//<![CDATA[
   var m3_u = (location.protocol=='https:'?'https://124.43.130.172/adserver/www/delivery/ajs.php':'http://124.43.130.172/adserver/www/delivery/ajs.php');
   var m3_r = Math.floor(Math.random()*99999999999);
   if (!document.MAX_used) document.MAX_used = ',';
   document.write ("<scr"+"ipt type='text/javascript' src='"+m3_u);
   document.write ("?zoneid=10");
   document.write ('&amp;cb=' + m3_r);
   if (document.MAX_used != ',') document.write ("&amp;exclude=" + document.MAX_used);
   document.write (document.charset ? '&amp;charset='+document.charset : (document.characterSet ? '&amp;charset='+document.characterSet : ''));
   document.write ("&amp;loc=" + escape(window.location));
   if (document.referrer) document.write ("&amp;referer=" + escape(document.referrer));
   if (document.context) document.write ("&context=" + escape(document.context));
   if (document.mmm_fo) document.write ("&amp;mmm_fo=1");
   document.write ("'><\/scr"+"ipt>");
//]]>--><a href='http://124.43.130.172/adserver/www/delivery/ck.php?n=acfa883c&amp;cb=INSERT_RANDOM_NUMBER_HERE' target='_blank'><img src='http://124.43.130.172/adserver/www/delivery/avw.php?zoneid=10&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=acfa883c' border='0' alt='' /></a>`;
    }
  },
};
</script>
<style scoped>
#homebannersection {
  flex: 1;
  background-color: white;
  padding: 10px 5px 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#welcomeUnderSection {
  padding: 0px;
  margin: 0px;
  height: 392px;
  text-align: center;
}

.owl2-carousel .owl2-item img {
  /* height: 50vh; */
  height: auto;
  width: 100%;
}

.text-center {
  text-align: center !important;
}

.col-container {
  display: flex;
  flex-wrap: wrap !important;
  width: 100%;
  margin-top: 2.5%;
  width: auto !important;
}

.col-table {
  flex: 1;
}

#welcomeSection {
  background-image: url('https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1637667037/cochchi/portal_user/1637667035549.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  border-radius: 5px;
  width: 100%;
  /* background-color: rgba(37, 130, 203, 0.7); */
}

.welcomeSection-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 8%;
  background-color: rgba(14, 105, 176, 0.8);
  border-radius: 5px;
}

#welcometxt {
  color: white;
  /* color: rgb(0 91 170); */
  font-size: 24px;
  font-weight: 600;
}

.content-top-w {
  margin-left: -15px;
  margin-right: -15px;
}

.btn-primary {
  background-color: rgb(0 91 170) !important;
  font-size: 18px;
  border-radius: 5px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100% !important;
}

.btn-secondary {
  background-color: rgb(180 180 180);
  font-size: 18px;
  color: white !important;
  border-radius: 5px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100% !important;
}

.btn:active:focus,
.btn:focus {
  outline: none !important;
  outline: none !important;
}

.btn-primary:active {
  border: none !important;
}
.btn-secondary:active {
  border: none !important;
}

.right-side-ad {
  height: 150px;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (min-width: 1650px) {
  .owl2-carousel .owl2-item img {
    height: 460px;
    width: 100%;
  }

  .slider-size {
    width: auto;
    /* width: 1188px; */
  }
}

@media screen and (max-width: 1600px) {
  #welcomeUnderSection {
    height: 367px;
  }

  .slider-size {
    width: 1080px;
  }
}

@media screen and (max-width: 1550px) {
  #welcomeUnderSection {
    height: 333px;
  }
}

@media screen and (max-width: 1500px) {
  #welcomeUnderSection {
    height: 319px;
  }
}

@media screen and (max-width: 1450px) {
  #welcomeUnderSection {
    height: 291px;
  }

  .slider-size {
    width: auto;
  }
}

@media screen and (max-width: 1300px) {
  #welcomeUnderSection {
    height: 240px;
  }
}

@media screen and (max-width: 1200px) {
  .col-container {
    flex-direction: column;
    height: auto;
  }

  .welcomeSection-inner {
    padding: 6%;
  }


  #welcomeUnderSection {
    height: 367px;
  }

  .slider-size {
    width: auto;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #homebannersection {
    margin-top: 0px;
    padding: 0px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #homebannersection {
    margin-top: 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media screen and (max-width: 768px) {
  #mainBannerUnderSection {
    text-align: center;
  }

  .col-container {
    display: block;
  }

  /* .slider-size {
    width: 740px;
  } */
}

@media screen and (max-width: 445px) {
  .col-container {
    width: auto !important;
  }

  .slider-size {
    width: auto;
  }

  /* .slider-size {
    width: 420px;
  } */
}
</style>