<template>
<div>
  <div class="module banner-left hidden-xs ">
    <div class="banner-sidebar banners">
        <div>
          <a :title="data.title" :href="data.url"> 
            <img :src="data.imgpath" alt="Banner Image"> 
          </a>
        </div>
      </div>
  </div> 
</div>
</template>
<script>
export default {
  name: 'left_menu',
  props: {
    data: {
      type: Object
    },
  },
  data: function () {
    return {
      features: [
          {image:'assets/image/catalog/banners/cat1.jpg',title:'Great Value',description:'Lorem Ipsum is simply dummy text'},
          {image:'assets/image/catalog/banners/cat1.jpg',title:'Secure Payment',description:'Lorem Ipsum is simply dummy text'},
          {image:'assets/image/catalog/banners/cat1.jpg',title:'24 x 7 Help',description:'Lorem Ipsum is simply dummy text'},
          {image:'assets/image/catalog/banners/cat1.jpg',title:'Island Wide Delivery',description:'Lorem Ipsum is simply dummy text'},
          {image:'assets/image/catalog/banners/cat1.jpg',title:'Open Store',description:'Lorem Ipsum is simply dummy text'},
          {image:'assets/image/catalog/banners/cat1.jpg',title:'Stay Touched',description:'Lorem Ipsum is simply dummy text'}
      ]
    }
  },
  beforemounted() {
    $(document).ready(function(){
      $('#cat_accordion').cutomAccordion ({
        eventType: 'click',
        autoClose: true,
        saveState: true,
        disableLink: true,
        speed: 'slow',
        showCount: false,
        autoExpand: true,
        cookie	: 'dcjq-accordion-1',
        classExpand	 : 'button-view'
      });  
    });
  },
  methods:{
    pushTo(pathlink) {
      this.$router.push(pathlink).catch(err => {})
    },
    chlidClass(obj) {
      if(obj.child){
        return 'hadchild';
      } else {
        return '';
      }
    }
  }
}
</script>
<style>
.static-cates ul li {
    padding: 0 15px;
    float: left;
    /* width: 16.66%; */
}

.static-cates ul li h4 {
margin-top:15px;
}
</style>
