<template>
  <div>
    <!-- <ul class="breadcrumb">
        <li><a @click="pushTo('/');"><i class="fa fa-home"></i></a></li>
        <li v-for="bc in currentbreadcrumb" :key="bc.href"><a @click="pushTo(bc.href);">{{bc.text}}</a></li> 
    </ul>-->
  </div>
</template>
<script>
export default {
  name: "Breadcrumb",
  components: {},
  data() {
    return {
      currentbreadcrumb: []
      // allbreadcrumbs:{
      //     'buyer-wishlist':['xxxx','ccccc','vvvvv'],
      //     'buyer-cart':['aaaa','bbbbb','ccccc']
      //     }
    };
  },
  created() {
    let self = this;
    this.$store.watch(
      function(state) {
        return state;
      },
      function() {
        self.getBreadcrumb();
      },
      {
        deep: true //add this if u need to watch object properties change etc.
      }
    );
    this.menuUnLock();
    this.getBreadcrumb();
  },
  watch: {
    $route(to, from) {
      this.menuUnLock();
      this.getBreadcrumb();
    },
    $store() {
      this.menuUnLock();
      this.getBreadcrumb();
    }
  },
  methods: {
    menuUnLock() {
      let keepMenuPaths = [
        "Product List Home Tabs",
        "Product List Main",
        "Product List",
        "Product List All"
        // "product"
      ];

      if (document.body.classList.contains("common-home")) {
        if (!keepMenuPaths.includes(this.$route.name)) {
          document.querySelector("body").classList.remove("common-home");
        }
      } else {
        if (keepMenuPaths.includes(this.$route.name)) {
          if (!document.body.classList.contains("common-home")) {
            var body = document.body;
            body.classList.add("common-home");
          }
        }
      }
    },
    getBreadcrumb() {
      this.currentbreadcrumb = this.$store.state.breadcrumb.breadcrumb.payload;
    },
    pushTo(href) {
      this.$router.push(href).catch(err => {});
    }
  }
};
</script>
<style></style>
