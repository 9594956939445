import Domain from "../domain";
export default {
    methods: {
        getOrderList: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/details?" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getOrderListCount: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/details/count?" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getAllOrdersForAUser: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/details",
                    // Domain.APP_DOMAIN + "portal/order/new/details",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getAllOrdersForAUserByMerchat: async function(params) {
            try {
                let response = await this.axios.get(
                    // Domain.APP_DOMAIN + "portal/order/cochchi/details",
                    Domain.APP_DOMAIN + "portal/order/new/details" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getAllOrderCountForAUserByMerchat: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/new/details/count?" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getSingleOrderDetails: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/details/" + params,
                    // Domain.APP_DOMAIN + "portal/order/new/details/"+params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getSingleOrderDetailsMyMearchat: async function(oid) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/line/cochchi/details?order_no=" + oid,
                    // Domain.APP_DOMAIN + "portal/order/new/details/"+params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getOrderById: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/details/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getStatusHistory: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/line/history/details/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        disputeDetails: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/dispute/reason",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putReturnOrderImage: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/product/upload/image", obj,
                    Domain.APP_BUYER_HEADER_FORM_DATA
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postReturnOrder: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/order/dipute",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postCreateDispute: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/order/dipute",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getNewDeliveryDispatchList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/dispute/details",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReturnOrder: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/dispute/details?" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReturnOrderCount: async function(params) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/count/dispute/details?" + params,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReturnOrderById: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/dispute/details/?order_no=" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putChangeStatusDeliverdById: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/order/dipute/status/toadmin",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putCancelOrder: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/order/cancel/status",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getReturnOrderStatusHistory: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/dispute/history/details/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getCancelOrderReasons: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/cancel/status",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putCancelOrderReasons: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/order/cancel/status",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        postDisputeChat: async function(obj) {
            try {
                let response = await this.axios.post(
                    Domain.APP_DOMAIN + "portal/product/dispute/chat",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getDisputeChat: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/product/dispute/chat/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getOderSettingListB: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/setting",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getDisputeReasonMainList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/dispute/reason/?active_status=1",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getDisputeReasonSubList: async function(id) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/order/dispute/sub/reason/" + id,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        putDisputeImage: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/product/review/upload/image",
                    obj,
                    Domain.APP_BUYER_HEADER_FORM_DATA
                );
                return response.data;
            } catch (error) {
                throw error.response.data;
            }
        },
        putDisputeOderBuyerSendToAdmin: async function(obj) {
            try {
                let response = await this.axios.put(
                    Domain.APP_DOMAIN + "portal/order/dipute/status/toadmin",
                    obj,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getProfileVoucherList: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "portal/voucher/details",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },

        getBankListInfo: async function() {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/order/bank/details",
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        getBranchListInfo: async function(bId) {
            try {
                let response = await this.axios.get(
                    Domain.APP_DOMAIN + "common/order/branch/details/" + bId,
                    Domain.APP_BUYER_HEADER
                );
                return response.data;
            } catch (error) {
                throw error;
            }
        },
    }
};